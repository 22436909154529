.largeCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-2xs);
  width: 100%;
}

@media screen and (width <= 1199px) {
  .largeCards {
    gap: var(--space-3xs);
  }
}

@media screen and (width <= 767px) {
  .largeCards {
    grid-template-columns: repeat(1, 1fr);
    gap: var(--space-4xs);
  }
}
