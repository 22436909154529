.wrapper {
  margin-bottom: 4.5rem;
}

.title {
  margin-bottom: 3rem;
  color: var(--gray-400);
}

.cities {
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  column-gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  transition: 0.3s height;

  --height: 4.5rem;
  --count: 3;
  --divider: 2;
  --remainder: 1;
}

.city {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--gray-300);
  border-bottom: 1px solid transparent;
  height: var(--height);
}

.city:nth-child(8),
.city:last-child {
  border-bottom-color: var(--gray-300);
}

.cityButton {
  background: var(--gray-300);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: var(--black);
  display: flex;
  align-items: center;
  padding: 16px 19px;
  border-radius: 40px;
  margin-left: auto;
  width: fit-content;
  transition:
    all 0.5s,
    background 1.2s;
}

.cityButton span {
  margin-right: 0;
  transition: 0.75s;
  white-space: nowrap;
  width: 0;
  overflow: hidden;
}

.city:hover .cityButton {
  padding: 16px 32px;
  background: var(--white);
}

.city:hover .cityButton span {
  margin-right: 8px;
  width: 120px;
}

.showBtn {
  color: var(--gray-400);
  margin-top: 2.5rem;

  /* before change mail */

  /* margin-bottom: 3rem; */
  display: flex;
  align-items: center;
  height: 22px;
}

.showBtn::after {
  content: '';
  background: url('assets/img/icons/arrow-bottom.svg') center right no-repeat;
  margin-left: 9px;
  width: 13px;
  height: 100%;
  display: block;
  transform: rotate(0);
  transition: 0.3s transform;
}

.showBtnActive::after {
  transform: rotate(180deg);
}

.cityOffer {
  width: 100%;
  background: var(--black) url('assets/img/bg/bgCities.svg') top right no-repeat;
  background-size: contain;
  color: var(--white);
  padding: 1.5rem;
  border-radius: 1rem;
}

.cityOffer p {
  max-width: 43.5rem;
}

.sectionTitle {
  position: absolute;
  top: 48px;
  left: 48px;
  z-index: 7;
}

.requestButton {
  background-color: var(--white);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 32px;
  border-radius: 40px;
  margin-left: auto;
  margin-top: 1.5rem;
  width: fit-content;
}

.requestButton svg,
.cityButton svg {
  fill: var(--black);
}

@media (width <= 1199px) {
  .sectionTitle {
    left: 40px;
  }
}

@media (width <= 1023px) {
  .cities {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }

  .cityOffer p {
    max-width: 22.5rem;
  }

  .requestButton,
  .cityButton {
    padding: 16px;
  }

  .requestButton span,
  .cityButton span {
    display: none;
  }

  .city:nth-child(8) {
    border-bottom: none;
  }

  .city:hover .cityButton {
    padding: 16px;
    background: var(--gray-300);
  }

  .city:hover .cityButton span {
    margin-right: 0;
    width: 0;
  }
}

@media (width <= 767px) {
  .cityOffer {
    background-image: none;
    padding: 1.25rem;
  }

  .showBtn {
    margin-top: 2rem;
    margin-bottom: 2.25rem;
  }

  .cities {
    --height: 4rem;
    --count: 6;
    --divider: 1;
    --remainder: 0;
  }
}

@media (width <= 575px) {
  .sectionTitle {
    top: 32px;
    left: 16px;
  }
}
