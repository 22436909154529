.carCard {
  display: flex;
  align-items: center;
  border-radius: var(--corner-radius-m);
  border: 1px solid var(--transparent-gray-200);
  gap: var(--space-3xs);
  padding: var(--space-5xs);
  width: 100%;
}

.carCard:hover,
.chosen {
  border-color: var(--red-300);
}

.imageBlock {
  position: relative;
  width: 120px;
  height: 72px;
  border-radius: var(--corner-radius-s);
  overflow: hidden;
  flex-shrink: 0;
}

.imageBlock img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
}

.dataBlock {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-start;
}

.dataBlock p {
  color: var(--gray-400);
}

.carCard:disabled,
.carCard:disabled p {
  color: var(--transparent-gray-200);
}

.carCard:disabled .imageBlock {
  opacity: 0.4;
}
