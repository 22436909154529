.resultsNumber {
  padding-bottom: var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
  color: var(--gray-400);
}

.tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--space-5xs);
  width: auto;
  margin-bottom: var(--space-s);
  overflow: auto;
}

.tabs::-webkit-scrollbar {
  display: none;
}

@media screen and (width <= 1199px) {
  .mediumCards,
  .largeCards {
    gap: var(--space-3xs);
  }
}

@media screen and (width <= 1023px) {
  .mediumCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width <= 767px) {
  .mediumCards,
  .largeCards {
    grid-template-columns: repeat(1, 1fr);
    gap: var(--space-4xs);
  }
}
