:root {
  --corner-radius-l: 32px;
  --corner-radius-m: 20px;
  --corner-radius-s: 12px;
  --corner-radius-xs: 4px;
}

@media screen and (width <= 575px) {
  :root {
    --corner-radius-l: 24px;
    --corner-radius-m: 16px;
  }
}
