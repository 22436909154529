.reviewSlider .navigation {
  display: grid;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 8px;
  grid-template-areas: 'prev next';
  grid-template-columns: 54px 54px;
}

.reviewSlider .navigation .prev,
.reviewSlider .navigation .next,
.experienceSlider .next,
.experienceSlider .prev {
  width: 54px;
  height: 54px;
  border: 1px solid var(--black);
  border-radius: 30px;
  background: var(--white) url('assets/img/franchise/icons/right-black.svg') no-repeat center;
  background-size: 16px;
}

.reviewSlider .navigation .prev {
  grid-area: prev;
  transform: rotate(180deg);
}

.reviewSlider .swiper-button-disabled {
  display: none;
}

.reviewSlider .navigation .next {
  grid-area: next;
}

.reviewSlider .swiper-slide {
  width: 100%;
  max-width: 887px;
}

.reviewSlider .swiper-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
}

.reviewSlider .pagination {
  width: fit-content;
  display: none;
  gap: 6px;
  margin: 16px auto 0;
}

.reviewSlider .swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  background: var(--gray-300);
  flex-shrink: 0;
  border-radius: 50%;
}

.reviewSlider .swiper-pagination-bullet-active {
  background: var(--orange-300);
}

/* EXPERIENCE SLIDER */

/* todo: отрефактировать и перенести в место использования */

.experience-swiper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
}

.experience-swiper .swiper-pagination {
  position: unset;
}

.experience-swiper .swiper-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
}

.experience-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 8px !important;
}

.experience-swiper .swiper-slide {
  width: 100%;
  padding-top: 61%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
}

.experienceSlider .prev {
  transform: rotate(180deg);
}

.experience-thumbnails .swiper-slide {
  border: 1px solid transparent;
  height: 104px;
  width: 104px;
}

.experience-thumbnails .swiper-slide-thumb-active {
  border-color: var(--red-300);
}

.experienceSlider .pagination {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 1rem;
}

.experienceSlider .pagination span {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--gray-300);
}

.experienceSlider span.swiper-pagination-bullet-active {
  background-color: var(--red-300);
}

@media screen and (width <= 1211px) {
  .experience-thumbnails .swiper-slide {
    height: 74px;
    width: 74px;
  }
}

@media (width <= 1199px) {
  .reviewSlider .swiper-slide {
    max-width: 784px;
  }
}

@media (width <= 1023px) {
  .reviewSlider .swiper-slide {
    max-width: 614px;
  }

  .experience-thumbnails .swiper-slide {
    height: 104px;
    width: 104px;
  }
}

@media (width <= 767px) {
  .reviewSlider .swiper-slide {
    max-width: 768px;
  }

  .reviewSlider .pagination {
    display: flex;
  }

  .reviewSlider .navigation {
    display: none;
  }

  .experienceSlider .pagination {
    display: flex;
  }
}
