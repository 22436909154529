.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 50;
  max-width: 1400px;
  background-color: var(--gray-200);
  border-radius: 0 0 var(--corner-radius-l) var(--corner-radius-l);
  transition:
    0.2s background-color,
    0.5s top ease;
}

.headerScrolled {
  background-color: var(--white);
}

.headerScrolled.headerHide {
  top: -100%;
}

.top {
  padding: 12px 40px;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.navLinks {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.bottom {
  padding: 20px 40px;
  background-color: var(--white);
  border-radius: var(--corner-radius-l);
  transition: 0.2s border-radius;
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.logo {
  height: 54px;

  img {
    width: auto;
    height: 100%;
  }
}

.headerScrolled .bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.link {
  border-radius: 50px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 16px;
  color: var(--gray-400);
  cursor: pointer;
  padding: 0 4px;
  outline: none;
  transition: 0.2s color;
  text-align: left;
  white-space: nowrap;
}

.link.specialLink {
  background-color: var(--gray-300);
  font-weight: var(--font-medium);
  padding: 0 12px;
}

.link svg {
  fill: var(--gray-400);
  transition: 0.2s fill;
}

.link:hover {
  color: var(--red-300);
}

.link:hover svg {
  fill: var(--red-300);
}

.link:focus {
  border: 1px solid var(--red-300);
}

.geo {
  display: flex;
  gap: 8px;
  margin-right: auto;
}

.popoverBtns {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.phone {
  margin-left: 44px;
}

.btn,
.mobileBtn {
  border-radius: 40px;
  border: 1px solid var(--gray-200);
  height: 54px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition:
    0.2s color,
    0.2s background-color,
    0.2s border-color;
  overflow: hidden;
}

.mobileBtn svg,
.btn svg,
.back svg {
  fill: var(--black);
  transition: 0.2s fill;
}

.linkToMap {
  padding: 0 2rem;
}

.mobileBtn,
.profile {
  width: 54px;
}

.btn:hover {
  background-color: var(--red-300);
  border-color: var(--red-300);
  color: var(--white);
}

.btn:hover svg path {
  fill: var(--white);
}

.btn:focus {
  border-color: var(--red-300);
}

.mobileBtn,
.back {
  display: none;
}

.back {
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
}

.popupBlock {
  display: none;
}

.services {
  flex-shrink: 0;
}

@media screen and (width <= 1440px) {
  .header {
    left: 20px;
    right: 20px;
    transform: none;
    width: initial;
  }
}

@media screen and (width <= 1199px) {
  .header {
    left: 1rem;
    right: 1rem;
  }

  .top {
    gap: 0.5rem;
  }

  .phone {
    margin-left: 4px;
  }

  .link {
    padding: 0 2px;
  }

  .top,
  .bottom {
    padding-left: 36px;
    padding-right: 36px;
  }

  .bottom {
    gap: 12px;
  }

  .popupBlock {
    display: flex;
    position: relative;
  }

  .menuButton {
    padding: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popupWrap {
    position: absolute;
    top: calc(100% + 0px);
    flex-direction: column;
    gap: 8px;
    right: 0;
    background: var(--white);
    z-index: 1;
    display: flex;
    padding: 12px;
    border: 1px solid var(--gray-300);
    border-radius: 12px;
  }

  .secondary {
    display: none;
  }

  .popupLink {
    font-size: 12px;
    line-height: 16px;
    color: var(--gray-400);
  }
}

@media screen and (width <= 1023px) {
  .header {
    padding-top: var(--space-5xs);
    left: 20px;
    right: 20px;
    transition:
      0.2s padding-top,
      0.5s top ease;
  }

  .headerScrolled {
    padding-top: 0;
  }

  .top,
  .btn,
  .services {
    display: none;
  }

  .burger {
    display: flex;
  }
}

@media screen and (width <= 767px) {
  .logo {
    height: 46px;
  }
}

@media screen and (width <= 575px) {
  .header {
    padding-top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
  }

  .headerScrolled {
    padding-top: 0;
  }

  .bottom {
    padding: 0.75rem 1rem;
    gap: 8px;
  }

  .popoverBtns {
    flex-direction: column;
  }

  .mobileBtn {
    display: flex;
  }

  .mobileBtn:not(.burger) {
    margin-left: auto;
  }

  .openSearch {
    .back {
      display: flex;
    }

    & > a,
    & .mobileBtn {
      display: none;
    }
  }
}
