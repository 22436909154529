.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-2xs);
  width: 100%;
  padding: 20px 20px 20px var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  form {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.dataWrapper {
  overflow: auto;
}

.carInfoSection {
  width: 100%;
  padding: var(--space-xs) var(--space-s) var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.legalInfoSection {
  width: 100%;
  padding: var(--space-xs) var(--space-s) var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
}

.addImageSection {
  width: 100%;
  padding: var(--space-xs) var(--space-s) var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
  align-items: flex-start;
}

.bottomSection {
  width: 100%;
  padding: var(--space-2xs) var(--space-s) var(--space-2xs);
  display: flex;
  justify-content: flex-end;
}

@media screen and (width <= 575px) {
  .header {
    padding: var(--space-4xs) var(--space-4xs) var(--space-4xs) var(--space-xs);
  }

  .carInfoSection {
    padding: var(--space-xs) var(--space-xs) var(--space-s);
    gap: var(--space-xs);
  }

  .legalInfoSection {
    padding: var(--space-xs) var(--space-xs) var(--space-s);
    gap: var(--space-s);
  }

  .addImageSection {
    padding: var(--space-xs) var(--space-xs) var(--space-s);
    gap: var(--space-3xs);
  }

  .bottomSection {
    padding: var(--space-3xs) var(--space-xs) var(--space-3xs);
    justify-content: flex-end;
  }
}

@media screen and (width <= 425px) {
  .bottomSection button {
    width: 100%;
  }
}
