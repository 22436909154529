.filter,
.rest,
.filterButton {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter {
  justify-content: space-between;
  width: 100%;

  svg {
    fill: var(--black);
  }
}

.filterButton {
  margin-right: auto;
  font-weight: var(--font-medium);
}

.count {
  background-color: var(--red-300);
  color: var(--white);
  display: block;
  height: 24px;
  width: 24px;
  line-height: 24px;
  border-radius: 50%;
  font-size: 12px;
}

.reset {
  color: var(--red-300);
}

@media screen and (width <= 768px) {
  .title,
  .reset,
  .count {
    display: none;
  }

  .activeButton {
    background-color: var(--red-300);
    color: var(--white);
  }

  // todo: разобраться с кнопками в проекте, сделать отдельный компонент
  .filterButton {
    width: 54px;
    height: 54px;
    border: 1px solid var(--transparent-gray-200);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
