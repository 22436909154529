.content {
  color: var(--white);
  padding: 0.75rem 1rem;
  background-color: var(--gray-400);
  border-radius: var(--corner-radius-s);
  position: relative;
  max-width: 13.25rem;
  outline: none;
  display: flex;
  align-items: flex-start;
  gap: 14px;
}

.close {
  display: none;
}

.arrow {
  fill: var(--gray-400);
  transform: rotate(180deg);
}

@media screen and (width <= 1023px) {
  .content {
    max-width: 14.75rem;
  }

  .close {
    display: block;
  }

  .close > svg {
    fill: var(--white);
  }
}
