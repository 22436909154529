.header {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.tabs {
  display: flex;
  gap: 8px;
}

.header h1 {
  margin-right: auto;
}

.map {
  margin: 0 -40px -40px;
}

@media screen and (width <= 1199px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }

  .map {
    margin: 0 -36px -36px;
  }
}

@media screen and (width <= 767px) {
  .header {
    gap: 1.5rem;
    margin-bottom: var(--space-5xs);
    border-bottom: 1px solid var(--transparent-gray-200);
    padding-bottom: var(--space-s);
  }

  .tabs {
    overflow: auto;
  }

  .tabs::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (width <= 575px) {
  .map {
    margin: 0 -1rem -1rem;
  }

  .tabs {
    width: 100%;
  }
}
