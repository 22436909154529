.error {
  width: 100%;
  padding: var(--space-2xs);
  display: flex;
  align-items: center;
  gap: var(--space-3xs);
  background: var(--red-100);
  border-radius: var(--corner-radius-m);
  color: var(--red-300);
  margin-top: 8px;
}

.error svg {
  fill: var(--red-300);
}
