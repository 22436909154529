.sectionWrapper {
  width: 100%;
  padding: 3rem 48px 72px;
  max-width: 1440px;
  margin: 0 auto;
}

.sectionHeader {
  margin: 0 0 64px;
}

@media (width <= 1199px) {
  .sectionWrapper {
    padding: 3rem 40px 72px;
  }
}

@media (width <= 1023px) {
  .sectionWrapper {
    padding: 3rem 32px 72px;
  }
}

@media (width <= 767px) {
  .sectionWrapper {
    padding: 36px 16px 44px;
  }

  .sectionHeader {
    margin: 0 0 40px;
  }
}
