@import '../../../../../styles/breakpoints';

$minusTop: 8px;
$locationTop: 64px;

.controls {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(calc(-#{($minusTop + $locationTop) / 2} - 150%));
  z-index: 1;

  button {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    fill: var(--black);
    width: 24px;
    height: 24px;
  }
}

.minus {
  position: absolute;
  top: $minusTop;
  transform: translateY(100%);
}

.location {
  position: absolute;
  top: $locationTop;
  transform: translateY(200%);
}

@media screen and (width <= 767px) {
  .controls {
    right: 36px;
  }
}

@media screen and (width <= 575px) {
  .controls {
    right: 16px;
  }
}

@media screen and (width <= $bp-420) {
  .controls button {
    width: 44px;
    height: 44px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
