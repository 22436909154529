/*
  Цвета не соответствуют текущему дизайну и являются симбиозом
  caready и franchise
 */
:root {
  --white: #fff;
  --black: #161616;
  --no-color: #fff0;
  --orange-100: #ffecdc;
  --orange-300: #e28634;
  --blue-100: #d7eaff;
  --blue-200: #0b4d98;
  --blue-300: #00428d;
  --red-100: #ffdada;
  --red-200: #c80000;
  --red-300: #d60505;
  --red-300-50: #d6050566;
  --gray-100: #f9f8f6;
  --gray-200: #f0eeea;
  --gray-300: #e7e4de;
  --gray-400: #71716f;
  --gray-500: #282828;
  --bg-gray: #eaeaea;
  --dark-gray: #1d1d1d;
  --transparent-gray-100: rgb(255 255 255 / 14%);
  --transparent-gray-200: rgb(87 70 44 / 8%);
  --transparent-gray-300: rgb(22 22 22 / 40%);
  --transparent-gray-400: rgb(22 22 22 / 70%);
  --black-text: #161616;
}
