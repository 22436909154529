.tag {
  margin-bottom: var(--space-2xs);
  background: var(--transparent-gray-200);
}

.mainTitle {
  margin-bottom: var(--space-xs);
}

.mainBlock {
  display: grid;
  grid-template-columns: 1fr 312px;
  gap: 136px;
  width: 100%;
  padding-bottom: var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.pageContent > button,
.pageContent > a {
  width: fit-content;
}

.pageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.pageContent > picture {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: var(--corner-radius-m);
}

.lowerBlock {
  width: 100%;
}

.lowerBlock > h4 {
  margin-bottom: var(--space-xs);
}

.lowerCards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--space-2xs);
}

@media screen and (width <= 1199px) {
  .mainBlock {
    grid-template-columns: 1fr 218px;
    gap: 0 94px;
  }

  .lowerCards {
    gap: var(--space-3xs);
  }
}

@media screen and (width <= 1023px) {
  .mainBlock {
    grid-template-columns: 1fr;
  }

  .lowerCards {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (width <= 575px) {
  .pageContent > button,
  .pageContent > a {
    display: none;
  }

  .lowerCards {
    grid-template-columns: 1fr;
    gap: var(--space-4xs);
  }
}
