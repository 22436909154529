.profile_main {
  display: grid;
  gap: 40px 24px;
  grid-template-columns: 312px 1fr;
  grid-template-rows: 394px auto;
  flex-shrink: 0;
  align-items: flex-start;
  grid-template-areas:
    'profile cars'
    'button cars';
}

.pageTitle {
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.profile_main > a {
  grid-area: profile;
}

.profile_main > div {
  max-width: 100%;
  grid-area: cars;
}

.logout {
  display: flex;
  gap: 8px;
  padding: 16px 32px;
  border-radius: 40px;
  border: 1px solid var(--transparent-gray-200);
  align-items: center;
  justify-content: center;
  width: fit-content;
  grid-area: button;
}

.logout svg {
  fill: var(--black);
}

@media (width <= 1199px) {
  .profile_main {
    gap: 40px 16px;
    grid-template-columns: 218px 1fr;
    grid-template-rows: 310px auto;
  }
}

@media (width <= 1023px) {
  .profile_main {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'profile'
      'cars'
      'button';
    grid-template-rows: auto;
    gap: 16px;
  }
}

@media (width <= 767px) {
  .profile_main {
    gap: 12px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .profile_main > div {
    margin-bottom: 20px;
  }
}

@media (width <= 575px) {
  .logout {
    width: 100%;
  }
}
