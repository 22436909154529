.popover {
  position: relative;
}

.popover-content {
  background-color: var(--white);
  padding: 1.5rem;
  border-radius: 20px;
  outline: none;
  box-shadow: var(--shadow-100);
  position: absolute;
  top: 56px;
  left: 60px;
  z-index: 22;
  width: max-content;
}

.popover-arrow {
  border-radius: 4px;
  background-color: var(--white);
  width: 2rem;
  height: 2rem;
  transform: rotate(45deg);
  display: block;
  position: absolute;
  top: -12px;
  left: 36px;
  z-index: 2;
}

@media screen and (width <= 1199px) {
  .popover-content {
    left: 52px;
  }
}

@media screen and (width <= 1023px) {
  .popover-content {
    position: fixed;
    bottom: 1rem;
    right: 20px;
    top: initial;
    left: initial;
  }

  .popover-arrow {
    display: none;
  }
}

@media screen and (width <= 575px) {
  .popover-content {
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
