.wrapper {
  padding: 24px 36px;
  border-radius: var(--corner-radius-m) var(--corner-radius-m) 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 8px;
  align-items: center;
  color: var(--gray-400);
}

.actionButton {
  width: 54px;
  height: 54px;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset {
  color: var(--red-300);
  margin-left: auto;
}

@media screen and (width <= 575px) {
  .wrapper {
    padding: 1rem;
  }
}
