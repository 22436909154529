.ratingLabel {
  margin-bottom: var(--space-4xs);
}

.ratingButtons {
  display: flex;
  align-items: center;
  gap: var(--space-5xs);
}

.ratingButtons > label {
  display: flex;
  flex-shrink: 0;
}

.ratingButtons > label > input[type='radio'] {
  display: none;
}

.ratingButtons div {
  display: flex;
  width: 54px;
  height: 54px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--transparent-gray-200);
  border-radius: var(--corner-radius-s);
  background: var(--white);
  transition:
    0.2s background,
    0.2s border;
}

.ratingButtons > label > div span {
  transition: 0.2s color;
}

.ratingButtons > label > div:hover {
  border: 1px solid var(--orange-300);
}

.ratingButtons input:checked + div {
  background: var(--orange-300);
  border: 1px solid var(--orange-300);
}

.ratingButtons > label input[type='radio']:checked + div span {
  color: var(--white);
}

@media screen and (width <= 575px) {
  .ratingButtons div {
    width: 48px;
    height: 48px;
  }
}
