@import '../../../styles/breakpoints';

.banner {
  display: flex;
  width: 100%;
  background: var(--black-text);
  border-radius: var(--corner-radius-m);
  position: relative;
  overflow: hidden;
  color: var(--white);
  padding: 1.5rem;
  height: 320px;
}

.banner::after {
  content: '';
  width: 100%;
  height: 100%;
  background: var(--no-color);
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.2s background;
  z-index: 2;
}

.banner:hover::after {
  background: var(--transparent-gray-200);
}

.imageBg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: block;
}

.imageBg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.imageBg.darken::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--transparent-gray-300);
}

.content {
  width: 100%;
  height: 100%;
  row-gap: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  justify-content: space-between;
}

.data {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.tag {
  background-color: var(--white);
  border-color: var(--no-color);
  color: var(--black);
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.btnMore {
  max-width: max-content;
  min-width: min-content;
}

@media screen and (width <= $bp-1200) {
  .banner {
    padding: 1rem;
  }
}

@media screen and (width <= $bp-1024) {
  .banner {
    height: unset;
  }
}

@media screen and (width <= $bp-576) {
  .btnMore {
    max-width: unset;
  }
}
