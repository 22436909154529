.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-2xs);
  width: 100%;
  padding: 20px 20px 20px var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--space-xs) 0 var(--space-s);
  overflow: auto;
}

.mainInfoSection {
  width: 100%;
  padding: 0 var(--space-s) var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.errorText {
  margin-bottom: var(--space-2xs);
  color: var(--red-300);
}

.mainInfos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-4xs);
}

.mainInfo {
  width: 100%;
  display: flex;
  gap: var(--space-5xs);
}

.mainInfo p:first-child {
  width: 40%;
  flex-shrink: 0;
  color: var(--gray-400);
}

.serviceInfoSection {
  width: 100%;
  padding: var(--space-xs) var(--space-s) var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
}

.textBox {
  margin-bottom: var(--space-3xs);
}

.textBox > p {
  color: var(--gray-400);
  margin-bottom: var(--space-6xs);
}

.serviceCard {
  background: var(--gray-100);
  border-radius: var(--corner-radius-m);
  width: 100%;
}

.servicesBlock {
  padding: var(--space-2xs);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-2xs);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.cardHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
}

.cardHeader h6:first-child {
  width: calc(100% - 168px);
}

.cardHeader h6:nth-child(2) {
  width: 72px;
}

.cardHeader h6:nth-child(3) {
  text-align: right;
  width: 73px;
}

.cardServices {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-5xs);
}

.serviceInfo {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 12px;
  grid-template-areas: 'number item';
  align-items: flex-start;
}

.serviceInfo p:first-child {
  grid-area: number;
}

.serviceInfo p:nth-child(2) {
  grid-area: item;
}

.serviceInfo p:nth-child(3) {
  grid-area: price;
  text-align: right;
}

.totalPrice {
  width: 100%;
  padding: var(--space-2xs);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ratingsSection {
  width: 100%;
  padding: var(--space-xs) var(--space-s) 0;
}

.ratingsTitle {
  margin-bottom: var(--space-2xs);
}

.ratingsBlocks {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  margin-bottom: var(--space-s);
}

.yourRatings {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  margin-bottom: var(--space-xs);
}

.ratingBox p {
  margin-bottom: var(--space-4xs);
}

@media screen and (width <= 575px) {
  .header {
    padding: var(--space-4xs) var(--space-4xs) var(--space-4xs) var(--space-xs);
  }

  .inner {
    padding: var(--space-xs) 0 var(--space-3xs);
  }

  .mainInfoSection {
    padding: 0 var(--space-xs) var(--space-xs);
  }

  .mainInfos {
    gap: var(--space-3xs);
  }

  .mainInfo {
    flex-direction: column;
    gap: var(--space-6xs);
    align-items: flex-start;
  }

  .mainInfo p:first-child {
    width: auto;
  }

  .serviceInfoSection {
    padding: var(--space-xs) var(--space-xs) var(--space-s);
    gap: var(--space-4xs);
  }

  .textBox {
    margin-bottom: var(--space-4xs);
  }

  .servicesBlock {
    padding: var(--space-3xs);
    gap: var(--space-2xs);
  }

  .cardHeader h6:first-child {
    width: 100%;
  }

  .cardHeader h6:nth-child(2),
  .cardHeader h6:nth-child(3) {
    display: none;
  }

  .cardServices {
    gap: var(--space-3xs);
  }

  .serviceInfo {
    grid-template-columns: 20px 1fr;
    gap: 8px 12px;
    grid-template-areas: 'number item';
    align-items: flex-start;
    padding-bottom: var(--space-3xs);
    border-bottom: 1px solid var(--transparent-gray-200);
  }

  .cardServices .serviceInfo:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .totalPrice {
    padding: var(--space-2xs) var(--space-3xs);
  }

  .ratingsSection {
    padding: var(--space-2xs) var(--space-2xs) 0;
  }

  .ratingsBlocks {
    gap: var(--space-2xs);
    margin-bottom: var(--space-l);
  }

  .ratingsSection > button {
    width: 100%;
  }
}
