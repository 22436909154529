.titleBlock {
  padding-top: 12px;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.footer {
  align-items: center;
  padding: var(--space-2xs) var(--space-s);
  justify-content: space-between;
}

.sendButton {
  padding: 0 1rem;
  width: 100%;
  max-width: 280px;
}

.form > div {
  max-width: 220px;
}

.textBlock {
  display: flex;
  width: fit-content;
  gap: var(--space-6xs);
  align-items: center;
  margin-right: auto;
  flex-wrap: wrap;
}

@media screen and (width <= 575px) {
  .titleBlock {
    padding-top: 3px;
  }

  .footer {
    padding: var(--space-3xs) var(--space-xs);
    flex-direction: column-reverse;
    gap: var(--space-3xs);
    text-align: center;
  }

  .footer > button:last-child {
    width: 100%;
  }

  .form > div {
    max-width: 720px;
  }

  .textBlock {
    align-items: center;
    margin-right: unset;
    flex-wrap: wrap;
  }
}
