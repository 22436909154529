.Warranty {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 16px;
  align-items: flex-end;
}

.Text,
.Guarantee {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Text {
  max-width: 560px;
}

.Text > h5 {
  color: var(--gray-400);
}

.Cards {
  display: grid;

  /* grid-template-columns: 424px 200px; */
  grid-template-columns: 1fr;
  gap: 24px;
}

.Cards > div {
  padding: 24px;
  border: 1px solid var(--red-300);
  border-radius: 32px;
}

.Guarantee {
  /* width: fit-content; */

  width: 100%;
  margin-left: auto;
}

.GuaranteeInfo {
  display: flex;
  gap: 14px;
  align-items: center;
}

.Quantity {
  display: flex;
  gap: 4px;
  padding: 12px 16px 8px;
  border-radius: 20px;
  background-color: var(--gray-100);
}

.Guarantee > p {
  color: var(--gray-400);
}

.Details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Button {
  background-color: var(--black);
  padding: 15px;
  border-radius: 50%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.Button > svg {
  fill: white;
}

@media (width <= 1199px) {
  .Warranty {
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }

  .Text > h3 {
    font-size: 26px;
  }

  .Text > h5 {
    font-size: 18px;
  }

  .Cards {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .Guarantee {
    width: 100%;
  }

  .Details {
    flex-direction: row;
    align-items: center;
    margin-top: auto;
  }
}

@media (width <= 1023px) {
  .Warranty {
    grid-template-columns: 1fr;
    gap: 64px;
  }

  /* todo: hid guarantee details */

  /* .Cards { */

  /*  grid-template-columns: 1fr 235px; */

  /*  gap: 16px; */

  /* } */

  .Details {
    flex-direction: column;
    height: 100%;
  }

  .Text {
    max-width: unset;
  }
}

@media (width <= 767px) {
  .Warranty {
    gap: 40px;
  }

  .Cards {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .Details {
    flex-direction: row;
  }
}

@media (width <= 575px) {
  .Cards > div {
    padding: 16px;
  }

  .Quantity {
    display: block;
  }
}
