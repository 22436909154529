.wrap {
  display: flex;
  max-width: 312px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-height: 394px;
  height: fit-content;
  border-radius: var(--corner-radius-m);
  background: var(--gray-100);
  transition: 0.2s background;
  padding: var(--space-2xs);
}

.wrap:hover {
  background: var(--gray-200);
}

.personBlock {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-3xs);
}

.info > p {
  color: var(--gray-400);
  display: none;
}

.avatarIcon {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 30px;
}

.avatarIcon svg {
  width: 24px;
  height: 24px;
  fill: var(--black);
}

.contacts {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  gap: var(--space-6xs);
}

.contacts > p {
  color: var(--gray-400);
}

@media screen and (width <= 1199px) {
  .wrap {
    max-width: 218px;
    min-height: 310px;
    padding: var(--space-3xs);
  }
}

@media screen and (width <= 1023px) {
  .wrap {
    max-width: 1023px;
    flex-direction: row;
    justify-content: left;
    min-height: unset;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: var(--space-6xs);
    align-items: flex-start;
  }

  .info > p {
    color: var(--gray-400);
    display: flex;
  }

  .contacts {
    display: none;
  }
}

@media screen and (width <= 767px) {
  .personBlock {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: var(--space-3xs);
  }

  .info {
    padding-top: 2px;
  }

  .info > p {
    color: var(--gray-400);
    display: flex;
    word-break: break-all;
  }
}
