.section {
  background: url('assets/img/franchise/advantages/bg.svg') no-repeat left top;
  background-size: cover;
}

.container {
  width: 100%;
  padding: 48px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
}

.cardList {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);
  z-index: 1;
}

.card {
  border-radius: 16px;
  background-color: var(--white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.textList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text {
  display: flex;
  gap: 4px;
}

.text::before {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: var(--red-300);
  margin-top: 5px;
  transform: translateY(50%);
}

.additionalText {
  color: var(--gray-400);
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

@media (width <= 1199px) {
  .section {
    padding: 48px 40px;
  }

  .cardList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 1023px) {
  .section {
    padding: 48px 32px;
  }
}

@media (width <= 767px) {
  .section {
    padding: 48px 16px;
  }

  .cardList {
    grid-template-columns: repeat(1, 1fr);
  }
}
