.subSection {
  width: 100%;
}

.subTitleBlock {
  width: 100%;
  margin: 0 0 48px;
}

.buttonsBlock {
  width: 100%;
  overflow: auto;
  margin-bottom: 64px;
}

.buttons {
  display: flex;
  gap: 8px;
  flex-shrink: 0;
  align-items: center;
}

.menuButton {
  padding: 16px 32px;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.38;
  color: var(--black);
  border: 1px solid var(--black);
  border-radius: 40px;
  flex-shrink: 0;
  background: var(--bg-gray);
}

.active {
  color: var(--white);
  border: 1px solid var(--red-300);
  background: var(--red-300);
}

.tabContent {
  width: 100%;
  display: grid;
  grid-template-columns: 50.9% auto;
  gap: 90px;
  align-items: flex-start;
}

.tabInformation {
  width: 100%;
}

.tabLabel {
  margin-bottom: 48px;
  font-weight: 500;
  color: var(--black);
}

@media (width <= 767px) {
  .subTitleBlock {
    margin: 0 0 36px;
  }

  .buttonsBlock {
    margin-bottom: 40px;
  }

  .tabLabel {
    margin-bottom: 36px;
  }
}
