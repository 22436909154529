.accordion_item {
  margin-bottom: 8px;
}

.trigger {
  background-color: var(--gray-100);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: var(--corner-radius-m);
}

.header {
  display: flex;
  align-items: center;
  gap: var(--space-3xs);
}

.icon {
  width: 54px;
  height: 54px;
  display: block;
}

.changedColor img {
  filter: brightness(0) saturate(100%) invert(11%) sepia(64%) saturate(6520%) hue-rotate(357deg)
    brightness(109%) contrast(102%);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon path {
  fill: var(--red-300);
}

.count {
  border-radius: 50%;
  background-color: var(--red-300);
  color: var(--white);
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border-radius: 50%;
  transition:
    0.1s background-color,
    0.1s transform;
}

.arrow svg {
  fill: var(--black);
}

.trigger[data-state='open'] .arrow {
  transform: rotate(180deg);
  background-color: var(--red-300);
}

.trigger[data-state='open'] .arrow svg {
  fill: var(--white);
}

.content {
  border: 1px solid var(--transparent-gray-200);
  border-radius: var(--corner-radius-m);
  padding: var(--space-2xs);
  background-color: var(--white);
  margin-top: var(--space-5xs);
}

.group:not(:last-child) {
  margin-bottom: var(--space-3xs);
}

.group h6 {
  color: var(--gray-400);
  margin-bottom: var(--space-5xs);
}

.item {
  padding: var(--space-3xs) 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.item:not(:last-child) {
  border-bottom: 1px solid var(--transparent-gray-200);
}

.price {
  flex-shrink: 0;
}

@media screen and (width <= 767px) {
  .header {
    gap: var(--space-5xs);
  }

  .header img {
    margin-right: var(--space-5xs);
  }

  .arrow {
    width: 2rem;
    height: 2rem;
  }

  .item {
    flex-direction: column;
    gap: var(--space-3xs);
  }

  .item span {
    margin-left: auto;
  }

  .group h6 {
    margin-bottom: var(--space-6xs);
  }
}
