.mainTitle {
  margin-bottom: var(--space-s);
}

.bonus {
  color: var(--red-300);
}

.priceBlock {
  margin-bottom: var(--space-2xs);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
}

.info {
  display: flex;
  align-items: center;
  gap: var(--space-3xs);
  color: var(--gray-400);
  width: auto;
}

.info svg {
  fill: var(--gray-400);
  width: 24px;
  height: 24px;
}

.mainBlock {
  display: grid;
  grid-template-columns: 312px 1fr 312px;
  gap: 24px;
  width: 100%;
  grid-template-areas: 'image contentBox swiper';
}

.SideImg {
  grid-area: image;
}

.pageContent > button,
.pageContent > a {
  width: fit-content;
}

.pageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  grid-area: contentBox;
}

.mapSection {
  border-radius: var(--corner-radius-l);
  overflow: hidden;
  // todo: это отступы .section, собрать по проекту и вынести
  margin: 0 -40px -40px;
}

.promosBlock {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  grid-area: swiper;
}

.swiperBlock {
  width: 100%;
  position: relative;
}

.navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
}

.pagination {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
}

.promosContainer {
  display: none;
}

.emptyPromos {
  grid-area: swiper;
}

@media screen and (width <= 1199px) {
  .mainBlock {
    grid-template-columns: 218px 1fr;
    gap: var(--space-s) 96px;
    grid-template-areas:
      'image contentBox'
      'promos promos';
  }

  .promosBlock {
    display: none;
  }

  .promosContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    /* gap: var(--space-3xs); */
    gap: var(--space-2xs);
    width: 100%;
    grid-area: promos;
  }

  .emptyPromos {
    grid-area: promos;
  }

  .mapSection {
    border-radius: var(--corner-radius-l);
    overflow: hidden;
    margin: 0 -36px -36px;
  }
}

@media screen and (width <= 1023px) {
  .mainBlock {
    grid-template-columns: 208px 1fr;
  }
}

@media screen and (width <= 767px) {
  .mainBlock {
    grid-template-columns: 1fr;
    gap: 32px;
    grid-template-areas:
      'image'
      'contentBox'
      'promos';
  }

  .SideImg {
    width: 230px;
  }

  .promosContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (width <= 575px) {
  .info {
    align-items: flex-start;
  }

  .pageContent > button,
  .pageContent > a {
    width: auto;
  }

  .mapSection {
    margin: 0 -1rem -1rem;
  }
}

@media screen and (width <= 475px) {
  .SideImg {
    width: 100%;
  }
}
