.form {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.title {
  // todo: есть у всех модалок, переписать
  padding-top: 12px;
}
