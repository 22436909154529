.topSection {
  width: 100%;
  background-image: url('assets/img/bg/red-black-bg.svg');
  background-position: calc(100% + 1px) calc(100%);
  background-repeat: no-repeat;
  background-size: 75% auto;
  min-height: 760px;
}

.sectionTitle {
  width: 100%;
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.textBlock {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  gap: var(--space-2xs);
  max-width: 648px;

  /* width: 46%; */
  width: 100%;
  padding-bottom: 165px;
}

.textBlock > p {
  max-width: 537px;
  width: 100%;
  color: var(--gray-400);
}

.decorationImage {
  position: absolute;
  bottom: -17%;
  right: 0;
  width: 55%;
}

.decorationImage img {
  height: auto;
  width: 100%;
}

@media (width <= 1199px) {
  .topSection {
    background-size: 100% auto;
    min-height: 815px;
  }

  .textBlock {
    max-width: 608px;

    /* width: 62%; */
    padding-bottom: 287px;
  }

  .textBlock > p {
    max-width: 530px;
  }

  .decorationImage {
    width: 70%;
    max-width: 700px;
    bottom: -15%;
  }
}

@media (width <= 1023px) {
  .topSection {
    min-height: 728px;
  }

  .textBlock {
    max-width: 608px;
    width: 100%;
    padding-bottom: 250px;
  }

  .textBlock > p {
    max-width: 530px;
  }

  .decorationImage {
    position: absolute;
    bottom: -13%;
    right: 0;
    width: 68%;
    max-width: 600px;
  }
}

@media (width <= 767px) {
  .topSection {
    min-height: unset;
  }

  .textBlock {
    padding-bottom: 205px;
  }

  .decorationImage {
    position: absolute;
    bottom: -11%;
    right: 0;
    width: 69%;
    max-width: 440px;
  }
}

@media (width <= 475px) {
  .decorationImage {
    right: -8px;
    width: 318px;
    bottom: -60px;
  }
}
