.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  padding: 1rem 1.5rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
}

.header {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--transparent-gray-200);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  font-weight: var(--font-medium);
}

.title {
  margin: auto;
}

.back {
  margin-right: auto;
  display: flex;
  column-gap: 8px;
  align-items: center;
  color: var(--red-300);

  svg {
    fill: var(--red-300);
  }
}

.content {
  height: 100%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 1fr;
}
