@import '../../../../styles/breakpoints';

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media screen and (width <= $bp-576) {
  .wrapper {
    height: unset;
  }
}
