.section
  width: 100%


.container
  width: 100%
  display: flex
  flex-direction: column
  padding: 48px
  max-width: 1440px
  margin: 0 auto


.sectionTitle
  color: var(--black-text)
  margin-bottom: 64px

.content
  display: grid
  grid-template-columns: 1fr
  grid-auto-flow: row
  grid-auto-rows: auto
  column-gap: 32px
  row-gap: 30px
  align-items: start

.imageContainer
  position: relative

  & img:last-child
    position: absolute
    width: 60%
    height: auto
    margin: auto
    bottom: 0
    left: 50%
    transform: translateX(-50%)

  & img:first-child
    width: 100%
    height: auto

.cardNumber
  width: 40px
  height: 40px
  border-radius: 10px
  background-color: var(--white)
  margin-bottom: 20px
  display: flex
  justify-content: center
  align-items: center
  font-size: 20px

@media(min-width: 768px)
  .content
    grid-template-columns: 1fr 1fr
    &Title
      grid-column-end: span 2
  .imageContainer
    grid-column: 1 / span 2

@media(min-width: 1024px)
  .content
    grid-template-columns: 2.8fr 2.8fr 2.2fr 2.2fr
    row-gap: 40px
  .imageContainer
    grid-column: 1 / span 2
    grid-row: 1 / span 3

@media(min-width: 1440px)
  .content
    column-gap: 64px