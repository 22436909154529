.ul {
  position: fixed;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  z-index: 50;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ul li {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ul li::after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--gray-300);
  border-radius: 50%;
}

@media screen and (width <= 1199px) {
  .ul {
    display: none;
  }
}
