.history-swiper {
  height: 470px;
  width: 100%;
}

.history-swiper .swiper-slide {
  width: 284px;
  transition: 0.3s width;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--space-l);
  position: relative;
  overflow: hidden;
}

.history-swiper .swiper-slide picture {
  width: 112px;
  transition: 0.2s width;
}

.history-swiper .swiper-slide.swiper-slide-active picture {
  width: 100%;
}

.history-swiper .swiper-slide::before {
  content: '';
  width: 1px;
  height: 24px;
  background: var(--transparent-gray-100);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.historySlider .prev {
  transform: rotate(180deg);
}

.historySlider .navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-5xs);
  margin-top: var(--space-l);
}

.historySlider .next,
.historySlider .prev {
  width: 54px;
  height: 54px;
  border: 1px solid var(--transparent-gray-100);
  border-radius: 30px;
  background: url('assets/img/icons/right-arrow.svg') no-repeat center;
  background-size: 24px;
}

.promo-swiper {
  width: 100%;
}

.promo-swiper .swiper-slide {
  width: 100%;
  flex-shrink: 0;
  display: flex;
}

.promo-swiper .prev {
  transform: rotate(180deg);
}

.promo-swiper .navigation {
  display: flex;
  align-items: center;
}

.promo-swiper .next,
.promo-swiper .prev {
  width: 32px;
  height: 32px;
  background: url('assets/img/icons/right-icon.svg') no-repeat center;
  background-size: 26px;
}

.promo-swiper .pagination span {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  cursor: pointer;
  background: var(--orange-100);
}

.promo-swiper .pagination .swiper-pagination-bullet-active {
  background: var(--red-300);
}

.text-swiper .swiper-wrapper {
  display: flex;
  margin-top: var(--space-s);
  width: 423px;
}

.text-swiper .swiper-slide {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-swiper .swiper-slide.swiper-slide-active h4 {
  color: var(--white);
}

.text-swiper .swiper-slide h4 {
  color: var(--no-color);
  text-align: center;
}

@media screen and (width <= 1199px) {
  .history-swiper {
    height: 335px;
  }

  .history-swiper .swiper-slide {
    width: 172px;
  }

  .history-swiper .swiper-slide picture {
    width: 78px;
  }

  .text-swiper .swiper-wrapper {
    width: 296px;
  }
}

@media screen and (width <= 1023px) {
  .history-swiper {
    height: 349px;
  }

  .history-swiper .swiper-slide {
    width: 184px;
  }

  .history-swiper .swiper-slide picture {
    width: 84px;
  }

  .text-swiper .swiper-wrapper {
    width: 320px;
  }
}

@media screen and (width <= 575px) {
  .history-swiper {
    height: auto;
  }

  .history-swiper .swiper-slide picture,
  .history-swiper .swiper-slide.swiper-slide-active picture {
    width: 100%;
  }

  .text-swiper,
  .text-swiper .swiper-wrapper {
    width: 100%;
  }
}
