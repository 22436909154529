:root {
  --space-6xl: 200px;
  --space-5xl: 160px;
  --space-4xl: 140px;
  --space-3xl: 120px;
  --space-2xl: 96px;
  --space-xl: 80px;
  --space-l: 64px;
  --space-m: 48px;
  --space-s: 40px;
  --space-xs: 32px;
  --space-2xs: 24px;
  --space-3xs: 16px;
  --space-4xs: 12px;
  --space-5xs: 8px;
  --space-6xs: 4px;
}

@media screen and (width <= 767px) {
  :root {
    --space-6xl: 140px;
    --space-5xl: 120px;
    --space-4xl: 96px;
    --space-3xl: 80px;
    --space-2xl: 64px;
    --space-xl: 48px;
    --space-l: 40px;
    --space-m: 36px;
    --space-s: 32px;
    --space-xs: 24px;
    --space-2xs: 20px;
  }
}
