/* todo: почитать неиспользуемые классы */
.tabContent {
  width: 100%;
  display: grid;
  grid-template-columns: 50% auto;
  gap: var(--space-m);
  align-items: flex-start;
}

.tabInformation {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
}

.tabLabel {
  font-weight: 500;
  color: var(--black);
}

.infoBlocks {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dataBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dataLabel {
  font-weight: 500;
  color: var(--gray-400);
}

.dataValue {
  font-weight: 400;
  color: var(--black);
}

.swiperBlock {
  overflow: hidden;
  width: 100%;
}

.sliderNavigation {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
}

.slideImage {
  display: flex;
}

.slideImage img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (width <= 1211px) {
  .subSection {
    margin-bottom: 140px;
  }
}

@media (width <= 1023px) {
  .tabContent {
    grid-template-columns: 100%;
  }
}
