.mainTitle {
  width: calc(100% - 448px);
  margin-bottom: var(--space-xs);
}

.mainBlock {
  display: grid;
  grid-template-areas:
    'content side'
    'services services';
  grid-template-columns: 1fr 312px;
  gap: 0 136px;
  width: 100%;
}

.pageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  grid-area: content;
}

.pageContent > picture {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: var(--corner-radius-m);
}

.sideBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  grid-area: side;
}

.otherNews, .otherCards, .lowerBlock  {
  display: flex;
  flex-direction: column;
  row-gap: var(--space-xs);
}

.lowerBlock {
  width: 100%;
  padding-top: var(--space-s);
  border-top: 1px solid var(--transparent-gray-200);
  margin-top: var(--space-s);
  grid-area: services;
}

.lowerCards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: var(--space-2xs);
}

@media screen and (width <= 1199px) {
  .mainTitle {
    width: 100%;
  }

  .mainBlock {
    grid-template-columns: 1fr 218px;
    gap: 0 94px;
  }

  .otherCards {
    gap: var(--space-3xs);
  }

  .lowerCards {
    gap: var(--space-3xs);
  }
}

@media screen and (width <= 1023px) {
  .mainBlock {
    grid-template-columns: 1fr;
    gap: 0;
    grid-template-areas:
      'content'
      'services'
      'side';
  }

  .lowerCards {
    grid-template-columns: 1fr 1fr;
  }

  .sideBlock {
    padding-top: var(--space-s);
    border-top: 1px solid var(--transparent-gray-200);
    margin-top: var(--space-s);
  }

  .sideBlock > a {
    display: none;
  }

  .otherCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (width <= 575px) {
  .lowerCards {
    grid-template-columns: 1fr;
    gap: var(--space-4xs);
  }

  .otherCards {
    grid-template-columns: 1fr;
    gap: var(--space-4xs);
  }
}
