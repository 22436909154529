.app {
  padding: 9.5rem 0 0;
  margin: 0 auto;
}

.topSectionWrapper {
  padding-top: 0;
  padding-bottom: 20px;
  min-height: unset;
}

.topSection {
  background: unset;
}

.contactsWrapper {
  display: flex;
  gap: var(--space-s);
  flex-wrap: wrap;
}

.contact {
  display: flex;
  gap: var(--space-6xs);
  color: var(--red-300);
  align-items: center;

  svg {
    color: var(--gray-300);
    width: 20px;
    height: 20px;
  }
}

@media screen and (width <= 1023px) {
  .app {
    padding: 110px 0 0;
  }
}

@media screen and (width <= 919px) {
  .heroSection img {
    display: none;
  }
}

@media screen and (width <= 575px) {
  .app {
    padding: 94px 0 0;
  }
}
