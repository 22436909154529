.trigger {
  border-radius: 30px;
  border: 1px solid var(--gray-200);
  padding: 0 1.5rem;
  height: 54px;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.2s border-color;
  background-color: var(--white);
  line-height: 20px;
  text-align: left;
}

.trigger ~ div[data-radix-popper-content-wrapper] {
  z-index: 3 !important;
}

.trigger.placeholder,
.trigger[data-placeholder] {
  color: var(--gray-400);
}

.trigger svg {
  transition: 0.1s transform;
  fill: var(--black);
}

.trigger[data-state='open'] {
  border-color: var(--red-300);
}

.trigger[data-state='open'] .icons > svg {
  transform: rotate(180deg);
}

.trigger:disabled {
  background-color: var(--gray-100);
  color: var(--gray-300);
}

.trigger[data-placeholder] {
  color: var(--gray-300);
}

.trigger:disabled svg {
  fill: var(--gray-300);
}

.trigger.error {
  border-color: var(--red-300);
}

.icons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.icons > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons > span > svg {
  fill: var(--gray-400);
}

.content {
  box-shadow: var(--shadow-100);
  border-radius: var(--corner-radius-m);
  background-color: var(--white);
  overflow: hidden;
  width: var(--radix-select-trigger-width);
  max-height: 248px;
}

.item {
  padding: 1rem 1.5rem;
  transition: 0.2s background-color;
  outline: none;
  cursor: pointer;
}

.item:hover,
.item:focus {
  background-color: var(--gray-100);
}

.item[data-state='checked'] {
  color: var(--red-300);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item[data-state='checked'] svg {
  fill: var(--red-300);
}
