.label {
  position: relative;
  display: block;
  width: 100%;
}

.label input {
  border-radius: 30px;
  border: 1px solid var(--gray-200);
  padding: 0 3.75rem;
  width: 100%;
  height: 54px;
  transition: 0.2s border-color;
  background-color: var(--white);
}

.label.labelInHeader input {
  background-color: var(--gray-200);
}

.label input::placeholder {
  color: var(--gray-400);
}

.label > svg,
.clear {
  fill: var(--gray-400);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.label > svg {
  left: 1.5rem;
}

.clear {
  right: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.clear > svg {
  fill: var(--gray-400);
}

.label input:hover {
  border-color: var(--gray-300);
}

.label input:focus {
  border-color: var(--red-300);
}
