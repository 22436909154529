.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 40px;
  background: var(--red-300);
  color: var(--white);
  font-weight: var(--font-medium);
  padding: 0 2rem;
  height: 54px;
  border: 1px solid var(--red-300);
  transition:
    0.3s color,
    0.2s background,
    0.2s border;
  gap: var(--space-5xs);
}

.btn span {
  color: var(--white);
  transition: 0.3s color;
}

.block {
  width: 100%;
}

.btn svg {
  fill: var(--white);
}

.btn.secondary {
  color: var(--red-300);
  border-color: var(--transparent-gray-200);
  background-color: var(--no-color);
}

.btn.black {
  background-color: var(--black);
}

.btn.black.secondary {
  color: var(--black);
  fill: var(--black);
  background-color: transparent;
}

.btn.black.secondary svg {
  fill: var(--black);
}

.btn.tab {
  font-weight: var(--font-regular);
}

.btn.black.secondary.tab span {
  color: var(--gray-400);
}

.btn.tab span {
  color: var(--white);
}

.tab.medium {
  padding: 0 1rem;
  font-size: 14px;
  line-height: 1.43;
  min-height: 36px;
  height: auto;
  word-break: break-word;
  flex-shrink: 1;
  text-align: left;
}

.btn:disabled {
  background-color: var(--transparent-gray-200);
  border: none;
  cursor: not-allowed;
  color: var(--gray-300);
}

.btn:disabled svg {
  fill: var(--gray-300);
}

.btn:not(:disabled):hover {
  background-color: var(--red-200);
}

.btn.secondary:not(:disabled):hover {
  background-color: var(--transparent-gray-200);
}

/* .btn.black.secondary.tab:not(:disabled):hover span {
  color: var( --red-300);
} */

.btn.tab:hover span {
  color: var(--white);
}

/* button-link */

.btn-text {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  color: var(--black-text);
  fill: var(--black-text);
  cursor: pointer;
}

.btn-text span.icon {
  display: block;
  height: 16px;
}

.btn-text input {
  display: none;
}

.btn-text svg {
  fill: inherit;
  width: 1rem;
  height: 1rem;
}

.btn-text.orange {
  color: var(--red-300);
  fill: var(--red-300);
}

.btn-text.orange:hover {
  color: var(--red-200);
  fill: var(--red-200);
}

.btn-text.gray {
  color: var(--gray-400);
  fill: var(--gray-400);
}

.btn-text.gray:hover {
  color: var(--black-text);
  fill: var(--black-text);
}
