.result {
  z-index: 3;
  box-shadow: var(--shadow-100);
  background-color: var(--white);
  padding: var(--space-4xs);
  width: var(--radix-popper-anchor-width);
  margin-top: var(--space-5xs);
  border-radius: var(--corner-radius-m);
}

.result > span {
  padding: var(--space-3xs);
  color: var(--gray-400);
  display: block;
}

.result > span > svg {
  fill: var(--gray-400);
}

.result ul {
  list-style: none;
}

.item {
  padding: 1rem var(--space-3xs);
  border-radius: var(--corner-radius-s);
  transition: 0.2s background-color;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.item:hover {
  background-color: var(--gray-100);
}

.item > div > p {
  margin-bottom: 4px;
}

.item > div > span {
  display: block;
  color: var(--gray-400);
}

.item svg {
  fill: var(--gray-400);
}
