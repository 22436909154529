// todo: разнести по файлам: общие стили карточек и стили для конкретной карточки
.card {
  width: 100%;

  /* height: 394px; */
  height: auto;
  min-height: 336px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: var(--corner-radius-m);
  position: relative;
}

.image {
  flex-shrink: 0;
}

.primary {
  background: var(--white);
  justify-content: space-between;
  color: var(--black-text);
  transition:
    0.2s background,
    0.3s border;
  height: auto;
  border: 2px solid var(--red-300-50);

  /* min-height: 394px; */
}

.secondary,
.tertiary {
  color: var(--black);
  background: var(--gray-100);
  row-gap: var(--space-5xs);
}

.secondary {
  justify-content: space-between;
}

.primary:hover {
  border-color: var(--red-300);
}

.secondary:hover,
.tertiary:hover {
  background: var(--gray-200);
  color: var(--black);
}

.tertiarySkeleton:hover {
  background: var(--gray-100);
}

.tertiary {
  padding: var(--space-2xs);
  align-items: center;
  height: auto;

  /* min-height: 414px; */
  min-height: 336px;
}

.textBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-5xs);
}

.bonus {
  color: var(--red-300);
}

.textBlock > h4 {
  word-break: break-word;
}

.primary span {
  border: 1px solid var(--gray-200);
  color: var(--gray-400);
}

.primary > .textBlock,
.secondary > .textBlock {
  padding: var(--space-2xs) var(--space-2xs) 0;
  z-index: 4;
}

.primarySkeletonImageWrapper {
  padding: 0 0 var(--space-2xs) var(--space-2xs);
  margin: 0 !important;
}

.primary > .image {
  width: 200px;
  height: 200px;
  margin: 8px;
}

.primary > .image img {
  filter: brightness(0) saturate(100%) invert(6%) sepia(11%) saturate(41%) hue-rotate(323deg)
    brightness(97%) contrast(94%);
}

.secondary > .image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 312px;
}

.tertiary > .image {
  width: 236px;
  height: 236px;
}

.primarySkeleton {
  border: 2px solid var(--gray-200);

  &:hover {
    border: 2px solid var(--gray-200);
  }
}

.tertiaryTitle {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.currencyIcon {
  height: 18px;
}

@media screen and (width <= 1199px) {
  .card {
    min-height: 292px;
  }

  .primary {
    height: auto;
    min-height: 292px;
  }

  .tertiary {
    height: auto;
    min-height: 330px;
    gap: 1rem;
    padding: 1rem;
    justify-content: flex-start;
  }

  .primary > .textBlock,
  .secondary > .textBlock {
    padding: var(--space-3xs) var(--space-3xs) 0;
  }

  .primarySkeletonImageWrapper {
    padding: 0 0 var(--space-3xs) var(--space-3xs);
  }

  .primary > .image {
    width: 140px;
    height: 140px;
    margin: 4px;
  }

  .tertiary > .image {
    height: 166px;
    width: 166px;
  }
}

@media screen and (width <= 1023px) {
  .card {
    min-height: 228px;
  }

  .primary {
    height: auto;
    min-height: 228px;
  }

  .tertiary {
    height: auto;

    /* min-height: 396px; */
  }

  .primary > .image {
    width: 100px;
    height: 100px;
    margin: 8px;
  }

  .secondary > .image {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    max-width: 194px;
  }

  .tertiary > .image {
    width: 252px;
    height: 252px;
  }
}

@media screen and (width <= 767px) {
  .card {
    min-height: 146px;
  }

  .card > .textBlock {
    padding: 0;
  }

  .primary {
    flex-direction: row-reverse;
    gap: 1rem;
    padding: var(--space-4xs);
    height: auto;
    min-height: 146px;
  }

  .primarySkeletonImageWrapper {
    padding: 0;
  }

  .primary > .textBlock {
    padding-top: 4px;
    padding-right: 4px;
  }

  .secondary > .textBlock {
    padding: var(--space-3xs);
  }

  .tertiary {
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    min-height: 146px;
    height: auto;
  }

  .tertiary > .textBlock {
    margin-top: 4px;
  }

  .primary > .image {
    width: 54px;
    height: 54px;
    margin: 0;
  }

  .secondary > .image {
    max-width: 158px;
  }

  .tertiary > .image {
    max-width: 64px;
    height: 64px;
  }
}
