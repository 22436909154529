.serviceInfos {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.photo {
  width: 100%;
  display: flex;
  border-radius: var(--corner-radius-m);
  margin-bottom: var(--space-4xs);
  overflow: hidden;
}

.scheduleBlock {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: var(--gray-400);
  margin-top: 16px;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--transparent-gray-200);
}

.schedule {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.servicePlace {
  width: 100%;
  padding-bottom: var(--space-3xs);
  border-bottom: 1px solid var(--transparent-gray-200);
  flex-direction: column;
  gap: 12px;
  display: flex;

  & > h5 {
    width: 100%;
  }
}

.titleCategory {
  color: var(--gray-400);
  margin-bottom: 8px;
  margin-top: 1rem;
}

.phone {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-400);
  padding: 1rem 0;
  border-bottom: 1px solid var(--transparent-gray-200);
}

.enroll {
  background-color: var(--white);
  padding-top: 1rem;
  margin-top: auto;
}

@media screen and (width <= 767px) {
  .photo {
    margin-bottom: var(--space-4xs);
  }
}
