.cars {
  display: flex;
  gap: 32px;
  position: relative;
  background-color: var(--gray-100);
  border-radius: 20px;
  padding: 24px 32px 32px 24px;
  width: 100%;
}

.carsWrap {
  width: 100%;
  padding-bottom: var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.cars_pic {
  width: 392px;
  height: 238px;
  overflow: hidden;
  border-radius: 12px;
  flex-shrink: 0;
}

.cars_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cars_main {
  display: flex;
  height: auto;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.groupButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cars_text {
  color: var(--gray-400);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 12px;
  padding-bottom: 24px;
}

.cars_personal {
  display: flex;
  gap: 8px;
}

.cars_edit {
  display: flex;
  gap: 8px;
}

.cars_edit_button,
.cars_delete_button {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gray-200);
  border-radius: 50%;
  gap: 8px;
}

.cars_edit_button svg,
.cars_delete_button svg {
  fill: var(--gray-400);
  width: 24px;
  height: 24px;
}

.history_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.see_all {
  padding: 16px 32px;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 40px;
}

.history_main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.history_block {
  padding: 24px;
  background-color: var(--gray-100);
  border-radius: 20px;
  padding-right: 0;
  width: 100%;
}

.history_text {
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.history_text_line {
  display: flex;
  gap: 8px;
}

.history_key {
  width: 140px;
  color: var(--gray-400);
}

.see_more {
  color: var(--red-300);
  display: flex;
  gap: 8px;
}

.see_more svg {
  fill: var(--red-300);
  display: flex;
  justify-content: center;
  align-items: center;
}

.maintenance_recommendations {
  padding-bottom: var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
  width: 100%;
}

.recommendation_main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  margin-top: 32px;
  gap: 24px;
}

.recommendation_block {
  padding: 24px;
  border-radius: 20px;
  width: 100%;
  position: relative;
  min-height: 222px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--white);
  overflow: hidden;
  gap: 8px;
}

.recommendation_text {
  width: 50%;
  max-width: 288px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
}

.recommendation_text h5 {
  max-width: 272px;
}

.recomendation_blue {
  background-color: var(--blue-300);
}

.recomendation_orange {
  background-color: var(--red-300);
}

.recomendation_btn {
  background-color: var(--white);
  color: var(--black);
  width: fit-content;
  z-index: 1;
  border-radius: 40px;
  padding: 16px 32px;
}

.recomendation_btn h6 {
  text-align: center;
}

.recommendation_pic {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
}

.recommendation_pic img {
  max-height: 222px;
  width: 326px;
  object-fit: cover;
}

.services_main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  margin-top: 32px;
}

.mini_cars_wrap {
  display: flex;
  gap: 8px;
  margin-bottom: 40px;
}

.mini_cars {
  display: flex;
  gap: 8px;
  overflow: auto;
  max-width: 100%;
}

.mini_cars::-webkit-scrollbar {
  display: none;
}

.mini_cars .button {
  display: flex;
  gap: 8px;
  padding-left: 4px;
  padding-right: 24px;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 31px;
  height: 54px;
  align-items: center;
  cursor: pointer;
  width: 182px;
  flex-shrink: 0;
}

.mini_cars .active {
  border: 1px solid var(--red-300);
}

.pic_mini {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.pic_mini img {
  object-fit: cover;
  height: 100%;
}

.add_mini {
  border: 1px solid var(--transparent-gray-200);
  border-radius: 40px;
  height: 54px;
  display: flex;
  padding: 16px 32px;
  color: var(--red-300);
  gap: 8px;
  align-items: center;
  width: fit-content;
  justify-content: center;
}

.add_mini svg {
  fill: var(--red-300);
  width: 24px;
  height: 24px;
}

.section_line {
  height: 1px;
  width: 100%;
  border-top: 1px solid var(--transparent-gray-200);
  margin-top: 40px;
  margin-bottom: 40px;
}

.mini_text > p:first-child {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
}

.maintenance_history {
  padding-bottom: var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.noCarsBlock,
.noCarsTextBlock {
  margin-bottom: var(--space-s);
}

.noCarsTextBlock > h2 {
  margin-bottom: var(--space-5xs);
}

.noCarsTextBlock > p {
  color: var(--gray-400);
}

@media (width <= 1199px) {
  .history_main {
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .history_main > div:nth-child(3) {
    display: none;
  }

  .recommendation_block {
    min-height: 218px;
  }

  .services_main {
    gap: 16px;
  }

  .recommendation_text {
    width: 64%;
  }

  .recommendation_pic {
    width: 38%;
  }

  .recommendation_pic img {
    max-height: 218px;
    width: 319px;
  }

  .cars_pic {
    width: 382px;
    height: 232px;
  }
}

@media (width <= 1023px) {
  .cars_pic {
    width: 100%;
    max-width: 304px;
    height: 186px;
  }

  .cars {
    flex-direction: column;
    padding: 24px;
  }

  .cars_main {
    width: 100%;
  }

  .history_text {
    gap: 16px;
  }

  .history_text_line {
    flex-direction: column;
    gap: 4px;
  }

  .recommendation_block button {
    width: auto;
    padding: 16px 20px;
  }

  .recommendation_block {
    min-height: 322px;
  }

  .services_main {
    grid-template-columns: repeat(2, 1fr);
  }

  .mini_cars_wrap {
    flex-direction: column;
  }

  .recommendation_text h5 {
    max-width: unset;
  }

  .cars_text {
    max-width: 100%;
  }

  .recommendation_text {
    width: 100%;
    max-width: 430px;
  }

  .recommendation_pic {
    width: 100%;
    height: auto;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  .recommendation_pic img {
    max-width: 320px;
    width: 100%;
    flex-shrink: 0;
    height: auto;
    max-height: unset;
  }
}

@media (width <= 767px) {
  .cars {
    padding: 16px;
    gap: 0;
  }

  .cars_main {
    margin-top: 24px;
  }

  .cars_edit_button {
    padding: 0 27px;
    width: auto;
    border-radius: 40px;
  }

  .cars_edit_button h6 {
    display: block;
  }

  .recommendation_block {
    padding: 16px;
  }

  .recommendation_main {
    flex-direction: column;
    gap: 12px;
  }

  .groupButtons {
    flex-direction: column;
    width: fit-content;
    gap: 8px;
  }

  .section_line {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

@media (width <= 640px) {
  .recommendation_main {
    grid-template-columns: 1fr;
  }

  .recommendation_pic > img {
    max-height: 172px;
    width: auto;
  }

  .recommendation_block {
    min-height: 266px;
  }
}

@media (width <= 640px) {
  .history_main {
    gap: 16px;
    grid-template-columns: 1fr;
  }

  .history_main > div:nth-child(2),
  .history_main > div:nth-child(3) {
    display: none;
  }
}

@media (width <= 575px) {
  .history_block {
    padding: 16px;
    margin-bottom: 78px;
  }

  .maintenance_history {
    position: relative;
    padding-bottom: 78px;
  }

  .see_all {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .see_all h6 {
    text-align: center;
  }

  .services_main {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
    margin-top: 24px;
  }

  .mini_cars_wrap {
    margin-bottom: 32px;
  }

  .mini_cars::-webkit-scrollbar {
    display: none;
  }

  .add_mini {
    width: 100%;
  }

  .recomendation_btn {
    padding: 16px 0;
  }

  .recommendation_pic img {
    max-height: 165px;
  }

  .groupButtons {
    width: 100%;
  }

  .groupButtons > button:first-child {
    padding: var(--space-3xs);
  }

  .cars_edit {
    display: grid;
    grid-template-columns: 1fr 54px;
  }
}

@media screen and (width <= 384px) {
  .cars_pic {
    height: 146px;
  }
}
