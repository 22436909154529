.title {
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
  display: flex;
  align-items: center;
  gap: var(--space-3xs);
}

.title > span {
  padding: 7px 16px;
  border-radius: 40px;
  border: 1px solid var(--transparent-gray-200);
  color: var(--black-text);
  font-size: 16px;
}

@media screen and (width <= 575px) {
  .title {
    align-items: flex-end;
    display: inline-block;
    width: 100%;
  }
}
