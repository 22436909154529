@keyframes overlay-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  animation: overlay-show 150ms cubic-bezier(0.16, 1, 0.3, 1);
  padding: var(--space-m);
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: var(--transparent-gray-400);
  overflow: hidden;
  z-index: 999;
  inset: 0;
}

.wrap {
  max-width: 648px;
  width: 100%;
  background: var(--white);
  border-radius: var(--corner-radius-l);
  display: grid;
  grid-template-rows: auto 1fr;
  max-height: 100%;
  overflow: hidden;
  margin: auto;
}

.close {
  width: 54px;
  height: 54px;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close svg {
  fill: var(--gray-400);
  width: 24px;
  height: 24px;
}

@media screen and (width <= 768px) {
  .overlay {
    padding: 0;
  }

  .wrap {
    min-height: 100%;
    border-radius: 0;
    max-width: unset;
  }

  .close {
    width: 32px;
    height: 32px;
  }

  .close svg {
    width: 16px;
    height: 16px;
  }
}
