.content {
  width: 100%;
}

.content h2 {
  font-size: 20px;
  line-height: 26px;
}

.content img {
  width: unset;
  max-width: 100%;
  border-radius: var(--corner-radius-m);
  overflow: hidden;
  margin: 18px 0;
}

.content iframe {
  aspect-ratio: 16/9;
  border-radius: var(--corner-radius-m);
  margin: 18px 0;
}

.content h3 {
  font-size: 24px;
}

.content div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.content ul {
  list-style: disc;
  padding-left: 30px;
}

.content > p > strong {
  font-weight: 500;
}

@media (width <= 1199px) {
  .content h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (width <= 1023px) {
  .content h3 {
    font-size: 22px;
  }
}

@media screen and (width <= 767px) {
  .content img {
    margin: 10px 0;
  }

  .content iframe {
    margin: 10px 0;
  }

  .content h3 {
    font-size: 20px;
  }
}
