.label {
  display: flex;
  gap: var(--space-6xs);
  width: fit-content;
  flex-direction: column;
}

.wrap {
  display: flex;
  flex-shrink: 0;
  gap: var(--space-4xs);
  align-items: flex-start;
  width: fit-content;
}

.label input[type='checkbox'] {
  opacity: 0;
}

.label a {
  color: var(--red-300);
}

.box {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid var(--gray-200);
  border-radius: var(--corner-radius-xs);
  background: inherit;
  transition:
    0.2s background,
    0.2s border;
}

.box svg {
  display: flex;
  width: 16px;
  height: 16px;
  fill: none;
  transition: 0.2s fill;
}

.wrap span {
  transition: 0.2s color;
  padding-top: 1px;
}

.label input[type='checkbox']:disabled + .wrap {
  cursor: not-allowed;
}

.label:hover .box,
.label input:hover + .wrap .box {
  border-color: var(--gray-400);
}

.label input:disabled + .wrap .box {
  background: var(--gray-100);
  border-color: var(--gray-200);
}

.label input:disabled + .wrap span {
  color: var(--gray-300);
}

.errorBox {
  border-color: var(--red-300);
}

.label input:disabled + .wrap .errorBox {
  border-color: var(--red-300);
}

.label:hover .wrap .errorBox {
  border-color: var(--red-300);
}

.label input:checked + .wrap .box {
  border-color: var(--red-300);
  background: var(--red-300);
}

.label input:checked + .wrap .box svg {
  fill: var(--white);
}

.label input:checked:disabled + .wrap .box {
  border-color: var(--gray-100);
  background: var(--gray-100);
}

.label input:checked:disabled + .wrap .box svg {
  fill: var(--gray-300);
}

.label input:checked + .wrap .errorBox {
  border-color: var(--red-300);
  background: inherit;
}

.label input:checked + .wrap .errorBox svg {
  fill: var(--red-300);
}

.label input:checked:disabled + .wrap .errorBox {
  border-color: var(--red-300);
}
