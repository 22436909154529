.btnAll {
  margin-top: 24px;
  padding: 16px 0;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 40px;
  width: 100%;
  display: none;
  text-align: center;
}

@media screen and (width <= 575px) {
  .btnAll {
    display: flex;
  }
}
