.time {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.controls {
  display: flex;
  gap: 8px;
}

.success {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.map {
  min-height: 320px;
  height: 100vh;
  margin: 0 -40px -40px;
}

.appointment_signup {
  background-color: var(--blue-300);
  padding: 24px;
  color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.success span {
  color: var(--gray-400);
}

.appointment_signup h5 {
  max-width: 288px;
}

.appointment_btn {
  color: var(--black);
  background-color: var(--white);
  border-radius: 40px;
  padding: 16px 32px;
  width: fit-content;
}

.signup_car {
  position: absolute;
  right: 0;
  bottom: 0;
}

.dayPartSelect {
  display: flex;
  row-gap: 8px;
  flex-direction: column;
}

.dayPart {
  justify-content: start;
  width: 100%;
}

.dayPartTitleWrapper {
  display: flex;
  flex-direction: column;
}

.dayPartTitle {
  margin-bottom: 0;
}

.timeDisclaimer {
  color: var(--gray-400);
  margin-bottom: 0;
}

.list {
  overflow-y: auto;
}

@media screen and (width <= 575px) {
  .time {
    grid-template-columns: repeat(2, 1fr);
  }

  .map {
    margin: 0 -1.5rem -1.5rem;
  }

  .controls {
    flex-wrap: wrap;
  }

  .controls button {
    width: calc(50% - 4px);
  }

  .appointment_signup {
    padding: 20px;
    gap: 20px;
  }

  .signup_car {
    display: none;
  }

  .success {
    gap: 24px;
  }
}
