.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--transparent-gray-200);
  padding-bottom: 2rem;
  margin-bottom: 40px;
}

@media screen and (width <= 767px) {
  .header {
    padding-bottom: 1.5rem;
  }

  .header a {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    z-index: 1;
  }
}
