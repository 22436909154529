.backButton {
  display: none;
}

.header {
  padding-top: 12px;
}

.textBlock > h4 {
  display: none;
}

.footer {
  display: flex;
  gap: 8px;
}

@media screen and (width <= 575px) {
  .header {
    display: none;
  }

  .backButton {
    display: inline-flex;
  }

  .textBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .textBlock > h4 {
    display: inline-flex;
  }

  .footer {
    flex-direction: column-reverse;
  }
}
