.crumbs {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--space-6xs);
  align-items: center;
  margin-bottom: 2rem;
  overflow: hidden;
}

.mobileCrumbs {
  display: none;
}

.crumbs svg,
.mobileCrumbs svg {
  width: 16px;
  height: 16px;
  fill: var(--gray-400);
  flex-shrink: 0;
}

.link {
  color: var(--gray-400);
  white-space: nowrap;
}

.link::first-letter {
  text-transform: uppercase;
}

.link:last-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.link:hover,
.link.active {
  color: var(--black-text);
}

.link:focus {
  outline: 1px solid var(--red-300);
  border-radius: var(--corner-radius-xs);
}

@media screen and (width <= 767px) {
  .crumbs {
    display: none;
  }

  .mobileCrumbs {
    display: flex;
    overflow: hidden;
    margin-bottom: 1.5rem;
    flex-wrap: nowrap;
    gap: var(--space-6xs);
    align-items: center;
    text-overflow: ellipsis;
  }

  .link:last-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
