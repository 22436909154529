.back {
  color: var(--red-300);
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--transparent-gray-200);
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  svg {
    fill: var(--red-300);
  }
}

.searchWrapper {
  border-radius: var(--corner-radius-m);
  background-color: var(--white);
  position: absolute;
  z-index: 2;
  top: 40px;
  bottom: 40px;
  left: 40px;
  width: 100%;
  max-width: 26.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
