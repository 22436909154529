.item {
  display: block;
  cursor: pointer;
  padding: 1rem 0;

  h6 {
    margin-bottom: 4px;
  }
}

.notFound {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-400);

  p {
    max-width: 208px;
    text-align: center;
  }
}
