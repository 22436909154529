.option {
  padding: 1rem 1.5rem;
  transition: 0.2s background-color;
  outline: none;

  &:not(.skeleton) {
    cursor: pointer;
  }

  &:not(.skeleton):hover,
  :focus {
    background-color: var(--gray-100);
  }


  &[data-state='checked'] {
    color: var(--red-300);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &[data-state='checked'] svg {
    fill: var(--red-300);
  }
}
