.label {
  display: block;
  position: relative;
  z-index: 1;
}

.label input,
.label textarea {
  border-radius: 30px;
  border: 1px solid var(--gray-200);
  padding: 15px 23px;
  width: 100%;
  transition: 0.2s border-color;
  background-color: var(--white);
  cursor: text;
  resize: none;
  display: block;
  line-height: 22px;
}

.label input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.label input::placeholder,
.label textarea::placeholder {
  color: var(--gray-400);
}

.label:not(.error) input:hover,
.label:not(.error) textarea:hover {
  border-color: var(--gray-300);
}

.label:not(.error) input:focus,
.label:not(.error) textarea:focus {
  border-color: var(--red-300);
}

.error input,
.error textarea {
  border-color: var(--red-300);
}

.label input:disabled,
.label textarea:disabled {
  background-color: var(--gray-100);
  color: var(--gray-400);
}

.label input:disabled::placeholder,
.label textarea:disabled::placeholder {
  color: var(--gray-300);
}

.label input:placeholder-shown ~ .check,
.label textarea:placeholder-shown ~ .check {
  display: none;
}

.icon,
.icons {
  width: 1rem;
  height: 1rem;
  position: absolute;
  z-index: 2;
  top: 19px;
  right: 1.5rem;
}

.icons {
  width: initial;
}

.icon svg {
  fill: var(--black);
}

.text {
  position: absolute;
  top: 1px;
  height: 52px;
  background: var(--white);
  right: 24px;
  display: flex;
  align-items: center;
  color: var(--gray-400);
}

.label input:not(:placeholder-shown) ~ .text {
  right: 48px;
}
