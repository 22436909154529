@import '../../../../../../styles/breakpoints.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  width: 100%;
}

.workStatus {
  padding-right: 32px;
}

@media screen and (width <= $bp-576) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 16px;
  }
}
