.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px var(--space-xs) var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.headerButtons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.headerText {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--space-4xs);
  padding-right: 54px;
}

.simpleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-2xs);
  width: 100%;
  padding: 20px 20px 20px var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--space-xs) var(--space-s) var(--space-s);
}

.codeField > div {
  width: 100%;
  max-width: 220px;
}

.buttonSection {
  width: 100%;
  display: flex;
  padding: var(--space-2xs) var(--space-s);
  justify-content: flex-end;
  border-top: 1px solid var(--transparent-gray-200);
}

@media screen and (width <= 575px) {
  .header {
    padding: 12px 12px var(--space-3xs) var(--space-xs);
    gap: 4px;
  }

  .headerText {
    padding-right: 32px;
  }

  .headerText p span {
    white-space: nowrap;
  }

  .simpleHeader {
    padding: 12px 12px 12px var(--space-xs);
  }

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: var(--space-2xs);
  }

  .fieldSection {
    padding: var(--space-xs) var(--space-xs);
    gap: var(--space-xs);
  }

  .codeField > div {
    width: 100%;
    max-width: unset;
  }

  .buttonSection {
    padding: var(--space-3xs) var(--space-xs);
  }

  .buttonSection > button {
    width: 100%;
  }
}
