.combobox div[data-radix-popper-content-wrapper] {
  z-index: 3 !important;
}

.icons {
  display: flex;
  gap: 8px;
  height: 1rem;
  transition: 0.2s transform;
  align-items: center;
}

.arrow {
  fill: var(--black);
}

.clear {
  fill: var(--gray-400);
}

.iconsOpen .arrow {
  transform: rotate(180deg);
}

.content {
  box-shadow: var(--shadow-100);
  border-radius: var(--corner-radius-m);
  background-color: var(--white);
  overflow: auto;
  width: var(--radix-popper-anchor-width);
  max-height: 248px;

  :not(:first-child) {
    .groupTitle {
      border-top: 1px solid var(--gray-200);
    }
  }
}

.groupTitle {
  padding: 1rem 1.5rem;
  color: var(--gray-400);
  border-bottom: 1px solid var(--gray-200);
}
