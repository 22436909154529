.history_block {
  padding: 24px;
  background-color: var(--gray-100);
  border-radius: 20px;
  width: 100%;
}

.history_text {
  margin-top: 32px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.history_text_line {
  gap: 8px;
  display: grid;
  grid-template-columns: 130px 1fr;
}

.history_key {
  color: var(--gray-400);
}

.history_value {
  word-break: break-all;
  white-space: break-spaces;
}

.see_more {
  color: var(--red-300);
  display: flex;
  gap: 8px;
}

.see_more svg {
  fill: var(--red-300);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width <= 1023px) {
  .history_text {
    gap: 16px;
  }

  .history_text_line {
    flex-direction: column;
    gap: 4px;
    display: flex;
  }
}

@media (width <= 575px) {
  .history_block {
    padding: 16px;
  }
}
