.form {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  padding: var(--space-xs) var(--space-s);
}

.title {
  padding-top: 12px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  width: 100%;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  width: 100%;
}

.footer {
  align-items: center;
  padding: var(--space-2xs) var(--space-s);
  gap: var(--space-3xs);
}

.textBlock {
  display: flex;
  width: fit-content;
  gap: var(--space-6xs);
  align-items: center;
  margin-right: auto;
}

@media screen and (width <= 575px) {
  .title {
    padding-top: 3px;
  }

  .errorBlock {
    padding: var(--space-xs);
  }

  .form {
    gap: var(--space-s);
    padding: var(--space-xs);
  }

  .footer {
    align-items: center;
    padding: var(--space-3xs) var(--space-xs);
    flex-direction: column-reverse;
    text-align: center;
  }

  .footer > button {
    width: 100%;
  }

  .textBlock {
    align-items: center;
    margin-right: unset;
    flex-wrap: wrap;
  }
}
