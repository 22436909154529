.titleBlock {
  padding-top: 12px;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  padding: var(--space-xs) var(--space-s);
}

.footer {
  padding: var(--space-2xs) var(--space-s);
}

@media screen and (width <= 575px) {
  .titleBlock {
    padding-top: 3px;
  }

  .footer {
    padding: var(--space-3xs) var(--space-xs);
  }
}
