.container {
  border: 1px dashed var(--red-300);
  border-radius: var(--corner-radius-m);
  height: 11.5rem;
  width: 100%;
  overflow: hidden;
  transition:
    0.1s background-color,
    0.1s border-color;
  position: relative;
}

.container:hover,
.container:focus {
  background-color: var(--gray-100);
  border-color: var(--red-200);
}

.container.error {
  border-color: var(--red-300);
}

.container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.havePreview {
  border: none;
  max-width: 184px;
}

.clear {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--white);
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear svg {
  fill: var(--black);
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  text-align: center;
}

.text p span {
  color: var(--red-300);
  cursor: pointer;
}

.icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--orange-100);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon svg {
  fill: var(--red-300);
}

.p {
  text-align: center;
}

.p > span {
  display: block;
}

.p > span::first-letter {
  text-transform: uppercase;
}

@media screen and (width <= 575px) {
  .container {
    height: 130px;
  }

  .text {
    gap: 0;
  }

  .icon {
    width: 54px;
    height: 54px;
  }

  .p span {
    display: none;
  }
}
