.wrapper {
  a {
    color: var(--red-300);
  }

  ul {
    list-style-type: none;

    li::before {
        display: inline-block;
        content: '–';
        margin-right: 6px;
      }

    ul li {
      margin-left: 12px;
    }
  }

  ol {
    padding-bottom: 16px;
    padding-inline-start: 24px;
    margin: 0;
  }

  table {
    padding: 8px 0;
    display: block;
    overflow-y: hidden;
    overflow-x: auto;
    table-layout: auto;
    counter-reset: ol-1 ol-2;

    ol {
      padding: 0;
      margin: 0;
    }
  }

  & > ol,
  :global(.footnotes) > ol {
    padding-left: 0;
  }

  p {
    display: inline;
  }

  tr {
    td, th {
      padding-right: 24px;
      padding-bottom: 12px;
      vertical-align: text-top;
    }

    &:last-child td {
      padding-bottom: 0;
    }

    td:last-child,
    th:last-child {
      padding-right: 0;
    }
  }

  h2 {
    counter-increment: h2;
    counter-reset: ol-1;
    padding-bottom: 12px;

    &::before {
      display: inline-block;
      content: counter(h2) '. ';
      margin-right: 8px;
    }
  }

  // поддержка 2х уровней вложенности
  ol {
    list-style-type: none;
    counter-reset: ol-1 calc(var(--start) - 1);
    display: flex;
    flex-direction: column;
    gap: 12px;

    li {
      counter-increment: ol-1;

      &::before {
        display: inline-block;
        content: counter(ol-1) '. ';
        width: 24px;
        margin-right: 2px;
      }

      ol {
        counter-reset: ol-2;
        padding-top: 12px;
        padding-bottom: 12px;

        li {
          counter-increment: ol-2;

          &::before {
            display: inline-block;
            content: counter(ol-1) '.' counter(ol-2) '. ';
            width: 32px;
            margin-right: 2px;
          }
        }
      }
    }
 }
}
