.modal_header {
  padding: 20px 20px 20px 40px;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 54px;
  gap: 2rem;
  width: 100%;
  border-bottom: 1px solid var(--transparent-gray-200);
}

.modal_header > div {
  display: grid;
  gap: 12px;
  padding-top: 12px;
}

.modal_header p {
  color: var(--gray-400);
}

.modal_header.small {
  padding: 20px 20px 0 40px;
  border-bottom: none;
}

.modal_inner {
  padding: 2rem 40px 40px;
  overflow: auto;
}

.modal_breadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px 8px;
  flex-wrap: wrap;
}

.modal_breadcrumbs span {
  display: block;
  color: var(--red-300);
  cursor: pointer;
  white-space: nowrap;
}

.modal_breadcrumbs .modal_breadcrumb_address {
  max-width: 124px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.modal_breadcrumbs svg {
  fill: var(--gray-400);
  width: 1rem;
  height: 1rem;
}

.modal_footer {
  display: flex;
  justify-content: right;
  padding: 24px;
  width: 100%;
  border-top: 1px solid var(--transparent-gray-200);
}

.modal_footer.smallBottom {
  border-top: none;
}

.modal_container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (width <= 575px) {
  .modal_header {
    padding: 12px 12px 14px 24px;
    grid-template-columns: 1fr 32px;
    gap: 12px;
  }

  .modal_header > div {
    padding-top: 4px;
  }

  .modal_header.small {
    padding: 120px 22px 12px;
    position: relative;
    justify-content: center;
    display: flex;
  }

  .modal_inner {
    padding: 1.5rem;
  }

  .modal_container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .modal_footer {
    padding: 1rem 1.5rem;
    margin-top: auto;
  }

  .modal_footer > button {
    width: 100%;
  }

  .modal_footer.smallBottom {
    padding: 1rem 22px;
    border-top: 1px solid var(--transparent-gray-200);
  }

  .modal_breadcrumbs .modal_breadcrumb_address {
    max-width: 72px;
  }
}
