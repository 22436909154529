.footer {
  width: 100%;
  max-width: 1440px;
  padding: var(--space-s) 40px 0;
  background: var(--gray-200);
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
}

.footerMain {
  display: grid;
  grid-template-columns: 161px 1fr;
  grid-template-areas: 'logo nav';
  align-items: flex-start;
  padding-bottom: var(--space-s);
  justify-content: space-between;
  column-gap: 128px;
  position: relative;
}

.footerMain a:first-child {
  grid-area: logo;
}

.footerMain .socials {
  display: none;
  grid-area: socials;
}

.navBlocks {
  display: flex;
  width: 100%;
  gap: var(--space-s);
  align-items: flex-start;
  grid-area: nav;
}

.navBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 124px;
  gap: 8px;
}

.navBlock p {
  color: var(--gray-400);
  margin-bottom: 4px;
}

.navBlock a,
.navBlock button {
  color: var(--black-text);
}

.navBlock a:hover,
.navBlock button:hover {
  color: var(--gray-500);
}

.subscribeCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: var(--corner-radius-m);
  background: var(--black-text);
  width: 100%;
  height: fit-content;
  padding: var(--space-xs);
  grid-area: form;
}

.subscribeCard h5 {
  color: var(--white);
}

.errorBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);
  width: 100%;
}

.errorBlock button {
  width: 100%;
}

.succesBlock p,
.errorBlock p,
.subscribeForm + p {
  color: var(--gray-400);
}

.subscribeForm {
  display: flex;
  flex-direction: column;
  gap: var(--space-5xs);
  width: 100%;
  margin: 8px 0;
}

.subscribeForm > p {
  color: var(--red-300);
}

.subscribeForm + p > a {
  color: var(--red-300);
}

.subscribeForm > button {
  display: none;
}

.inputWrap {
  display: grid;
  grid-template-columns: 1fr 54px;
  gap: var(--space-5xs);
  align-items: flex-start;
}

.bottom {
  width: 100%;
  padding: var(--space-2xs) 0;
  border-top: 1px solid var(--gray-300);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legalInfo {
  display: flex;
  gap: var(--space-s);
  align-items: center;
  justify-content: space-between;
}

.legalInfo p,
.legals a {
  color: var(--gray-400);
}

.legals {
  width: fit-content;
  gap: var(--space-s);
  display: flex;
  align-items: start;
}

.legals a:hover {
  color: var(--black-text);
}

.socials {
  display: flex;
  width: fit-content;
  gap: var(--space-5xs);
}

.socials a {
  display: flex;
  width: 54px;
  height: 54px;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: var(--white);
}

.socials svg {
  width: 24px;
  height: 24px;
  fill: var(--black-text);
}

.phone {
  max-width: unset;

  a {
    white-space: nowrap;
  }
}

@media screen and (width <= 1199px) {
  .footer {
    padding: var(--space-s) 36px 0;
  }

  .footerMain {
    /* todo: hid subscription */

    /* grid-template-columns: 161px 230px 374px; */

    justify-content: space-between;
  }

  .navBlocks {
    /* todo: hid subscription */

    /* flex-direction: column; */
    gap: var(--space-s);
    justify-content: unset;
  }

  .subscribeCard {
    flex-direction: column;
    gap: 16px;
  }

  .legalInfo {
    width: 100%;
  }
}

@media screen and (width <= 1023px) {
  .footerMain {
    /* todo: hid subscription */

    /* todo: hid vk and tg */

    /* grid-template-areas: */

    /*  'logo form' */

    /*  'nav form'; */
    grid-template-areas:
      'logo .'
      'nav nav';
    grid-template-columns: 194px 60%;
    grid-template-rows: auto auto;
    gap: 40px;
    padding-bottom: var(--space-s);
  }

  .footerMain .socials {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 40px;
  }

  .bottom {
    padding: 40px 0;
  }

  .legalInfo {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: var(--space-s);
  }

  .legals {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 16px 32px;
  }

  .bottom .socials {
    display: none;
  }
}

@media screen and (width <= 640px) {
  .footerMain {
    grid-template-areas:
      'logo'
      /*todo: hid subscription*/
      /*'form'*/
      'nav';

    /* todo: hid vk and tg */

    /* 'socials'; */
    grid-template-columns: auto;

    /* todo: hid subscription */

    /* todo: hid vk and tg */

    /* grid-template-rows: auto auto auto auto; */
    grid-template-rows: auto auto;
    gap: 32px 24px;
  }

  .footerMain .socials {
    display: flex;
    position: unset;
    right: 0;
    bottom: 40px;
  }

  .logo {
    height: 46px;

    img {
      width: auto;
      height: 100%;
    }
  }
}

@media screen and (width <= 575px) {
  .footer {
    padding: 32px 16px 0;
  }

  .subscribeForm {
    margin-top: 4px;
  }

  .inputWrap {
    grid-template-columns: 1fr;
  }

  .inputWrap button {
    display: none;
  }

  .subscribeForm > button {
    display: flex;
    width: 100%;
    margin-top: 4px;
  }

  .bottom {
    padding: var(--space-s) 0;
  }
}

@media (width <= 420px) {
  .navBlocks {
    flex-wrap: wrap;
  }
}
