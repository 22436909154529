.history_main {
  display: flex;
  flex-direction: column;
}

.history_blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 32px;
}

.section_line,
.header_line {
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--transparent-gray-200);
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (width <= 1199px) {
  .history_blocks {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .header_line {
    margin-top: 32px;
  }
}

@media (width <= 767px) {
  .history_blocks {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
    margin-top: 24px;
  }

  .section_line {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .header_line {
    margin-top: 24px;
    margin-bottom: 32px;
  }
}
