.noData {
  color: var(--gray-400);
}

.cardsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: var(--space-2xs);
  width: 100%;
}

.electricIcon {
  display: inline-block;
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_729_42069)'%3E%3Cpath d='M13.3761 7.8237L6.3767 15.8266C6.33133 15.8814 6.27553 15.9252 6.21302 15.9551C6.15051 15.985 6.08274 16.0003 6.01424 16C5.93387 15.9991 5.85476 15.9786 5.78301 15.94C5.68781 15.8852 5.61157 15.7993 5.56546 15.6948C5.51935 15.5904 5.5058 15.473 5.52678 15.3598L6.4392 10.4713L2.83953 9.0308C2.76262 8.99896 2.69405 8.94777 2.63965 8.88159C2.58524 8.81541 2.54662 8.73619 2.52705 8.65066C2.50804 8.56484 2.50952 8.47529 2.53136 8.39023C2.55319 8.30517 2.59469 8.22732 2.65204 8.16382L9.6514 0.160919C9.72612 0.0792112 9.82342 0.025232 9.92901 0.00691375C10.0346 -0.0114045 10.1429 0.00690435 10.238 0.0591496C10.3331 0.111395 10.4101 0.194821 10.4574 0.297169C10.5048 0.399517 10.5202 0.515388 10.5013 0.627755L9.58891 5.52286L13.1886 6.96339C13.2659 6.99333 13.3349 7.04339 13.3895 7.10903C13.444 7.17468 13.4824 7.25382 13.501 7.33928C13.5196 7.42474 13.5179 7.51381 13.4961 7.5984C13.4743 7.683 13.433 7.76044 13.3761 7.8237Z' fill='%2371716F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_729_42069'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  margin-left: 8px;
}

@media screen and (width <= 1199px) {
  .cardsWrapper {
    gap: var(--space-3xs);
  }
}

@media screen and (width <= 1023px) {
  .cardsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width <= 767px) {
  .cardsWrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: var(--space-4xs);
  }
}
