:root {
  body {
    ::-webkit-scrollbar {
      width: 16px;
      height: 16px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 9999px;
      background-clip: padding-box;
      background-color: var(--gray-200);
      border: 6px solid rgb(0 0 0 / 0%);
    }
  }
}

body {
  pointer-events: auto !important;
}

html,
body {
  font-family: 'Gotham Pro', sans-serif;
  font-weight: var(--font-regular);
  font-size: var(--font-size);
  color: var(--black-text);
  line-height: var(--line-height);
  background-color: var(--gray-200);
}

.app {
  padding: 9.5rem 20px 0;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;
  min-height: 100svh;
}

.section {
  padding: 40px;
  background-color: var(--white);
  border-radius: var(--corner-radius-l);
  overflow: hidden;
  margin-bottom: var(--space-5xs);
  position: relative;
}

.label,
.error,
.description {
  font-size: 0.875rem;
  line-height: 1.43;
  display: block;
}

.label {
  margin-bottom: var(--space-5xs);
}

.error {
  color: var(--red-300);
  margin-top: var(--space-5xs);
}

.description {
  color: var(--gray-300);
  margin-top: var(--space-5xs);
}

.error + .description {
  margin-top: var(--space-6xs);
}

.hidden {
  overflow: hidden;
}

.ruble_back {
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.784 23.0001V0.1521H8.79999C11.5947 0.1521 13.664 0.717433 15.008 1.8481C16.3733 2.97877 17.056 4.62143 17.056 6.7761C17.056 8.22677 16.7253 9.47477 16.064 10.5201C15.4027 11.5441 14.4213 12.3334 13.12 12.8881C11.84 13.4428 10.2507 13.7201 8.35199 13.7201H5.66399V23.0001H2.784ZM0 18.6481V16.5681H10.944V18.6481H0ZM0 13.7201V11.2881H7.32799V13.7201H0ZM7.93599 11.2881C9.23732 11.2881 10.336 11.1494 11.232 10.8721C12.1493 10.5948 12.8533 10.1361 13.344 9.4961C13.8347 8.8561 14.08 7.98143 14.08 6.8721C14.08 5.42143 13.632 4.3441 12.736 3.6401C11.84 2.9361 10.4427 2.5841 8.54399 2.5841H5.66399V11.2881H7.93599Z' fill='%23161616'/%3E%3C/svg%3E%0A");
  width: 17px;
  height: 23px;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.ruble_back.small {
  background-size: 10px;
}

.ruble_back.alt {
  background-size: 10px;
  height: 17px;
}

.ql-size-small {
  font-size: 12px;
}

@media screen and (width <= 1199px) {
  .app {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section {
    padding: 36px;
  }
}

@media screen and (width <= 1023px) {
  .app {
    padding: 110px 20px 0;
  }

  .section {
    padding: 36px;
  }
}

@media screen and (width <= 575px) {
  .app {
    padding: 94px 0.5rem 0;
  }

  .section {
    padding: 1rem;
  }
}
