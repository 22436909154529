.section {
  display: flex;
  padding: 0;
  height: 564px;
  justify-content: space-between;
  align-items: flex-end;
}

.content {
  display: flex;
  gap: var(--space-s);
  flex-direction: column;
  max-width: 674px;
  flex-shrink: 0;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 12px 0 40px;
}

.imgWrapper {
  height: 100%;
}

.img {
  object-position: left bottom;
  object-fit: cover;
  height: 100%;
  width: auto;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 12px 40px 40px;
}

.footer::before {
  content: '';
  height: 1px;
  background: var(--transparent-gray-300);
  display: block;
  width: 100%;
}

@media screen and (width <= 1199px) {
  .content {
    max-width: 520px;
  }

  .titleWrapper {
    padding: 36px 12px 0 36px;
  }

  .footer {
    padding: 0 12px 36px 36px;
  }
}

@media screen and (width <= 1023px) {
  .content {
    max-width: 460px;
  }
}

@media screen and (width <= 919px) {
  .section {
    height: unset;
    flex-direction: column;
  }

  .content {
    max-width: unset;
  }

  .titleWrapper {
    padding: 36px 36px 0 36px;
  }

  .footer {
    padding: 0 36px 36px 36px;
  }

  .imgWrapper {
    align-self: flex-end;
  }

  .img {
    height: auto;
    width: 100%;
  }
}

@media (width <= 767px) {
  .titleWrapper {
    gap: 8px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: var(--space-s);
  }
}

@media (width <= 575px) {
  .titleWrapper {
    padding: 16px 16px 0 16px;
  }

  .footer {
    padding: 0 16px 16px 16px;
  }
}
