.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.content {
  color: var(--gray-400);
}

.closed {
  color: var(--red-300);
}

.dot {
  content: '';
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: var(--gray-400);
  vertical-align: middle;
  margin: 0 8px;
  border-radius: 50%;
}

@media screen and (width <= 360px) {
  .content {
    font-size: 0.74rem;
  }
}
