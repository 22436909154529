.wrap {
  display: flex;
  flex-direction: column;
  max-width: 984px;
  width: 100%;
  border-radius: var(--corner-radius-m);
  background: var(--gray-100);
  transition: 0.2s background;
  padding: var(--space-2xs);
  height: fit-content;
  min-height: 394px;
}

.topBlock {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--space-2xs);
}

.myCars {
  display: flex;
  width: fit-content;
  flex-shrink: 0;
  gap: var(--space-3xs);
  align-items: center;
  margin-right: auto;
}

.carIcon {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 30px;
}

.carIcon svg {
  width: 24px;
  height: 24px;
  fill: var(--black);
}

.carsTextBox {
  display: flex;
  flex-direction: column;
  gap: var(--space-6xs);
  align-items: flex-start;
}

.carsTextBox span,
.carsTextBox p {
  color: var(--gray-400);
}

.carsWrapper {
  width: 100%;
}

.carsWrapper + button {
  display: none;
}

.ownedCars {
  width: 100%;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--space-2xs);
}

.ownedCarWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 296px;
  background: var(--gray-100);
  transition: 0.2s background;
}

.cardImageOuter {
  width: 100%;
  display: flex;
  position: relative;
  border-radius: var(--corner-radius-s);
  overflow: hidden;
}

.cardImageInner {
  width: 100%;
  padding-top: 58.8%;
}

.cardImageOuter img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 0;
  top: 0;
}

.carInfo {
  display: flex;
  flex-direction: column;
  gap: var(--space-4xs);
  align-items: flex-start;
  margin-top: var(--space-3xs);
}

.infoData {
  display: flex;
  flex-direction: column;
  gap: var(--space-6xs);
  align-items: flex-start;
}

.infoData > p {
  color: var(--gray-400);
}

.addAutoButton {
  width: 100%;
  height: 268px;
  border: 1px solid var(--transparent-gray-200);
  border-radius: var(--corner-radius-s);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gray-100);
}

.addAutoButton:hover {
  background: var(--gray-200);
}

.buttonInside {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: var(--space-3xs);
  align-items: center;
}

.buttonInside svg {
  width: 32px;
  height: 32px;
  fill: var(--red-300);
}

.buttonInside h6 {
  color: var(--red-300);
}

@media screen and (width <= 1199px) {
  .wrap {
    max-width: 686px;
    padding: var(--space-3xs);
    min-height: 310px;
  }

  .topBlock {
    margin-bottom: var(--space-3xs);
  }

  .ownedCars {
    gap: var(--space-3xs);
  }

  .ownedCarWrap {
    max-width: 207px;
  }

  .carInfo {
    gap: var(--space-5xs);
    margin-top: var(--space-4xs);
  }

  .addAutoButton {
    height: 208px;
  }
}

@media screen and (width <= 1023px) {
  .wrap {
    max-width: 1023px;
    min-height: 304px;
    margin-bottom: 24px;
  }

  .ownedCarWrap {
    max-width: 197px;
  }

  .addAutoButton {
    height: 202px;
  }
}

@media screen and (width <= 767px) {
  .wrap {
    max-width: 272px;
  }

  .topBlock > button {
    display: none;
  }

  .myCars {
    margin-right: unset;
  }

  .carsWrapper {
    position: relative;
    margin-bottom: var(--space-3xs);
  }

  .carsWrapper + button {
    display: flex;
    width: 100%;
  }

  .carsTextBox h5 {
    font-size: 1rem;
  }

  .carsWrapper.gradient::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 40px;
    left: 0;
    bottom: 0;
  }

  .ownedCars {
    grid-template-columns: 1fr;
  }

  .ownedCarWrap {
    flex-direction: row;
    max-width: 240px;
    gap: var(--space-4xs);
    align-items: center;
  }

  .cardImageOuter {
    max-width: 90px;
  }

  .cardImageInner {
    padding-top: 60%;
  }

  .carInfo {
    gap: var(--space-6xs);
    margin-top: unset;
  }

  .infoData {
    display: flex;
    flex-direction: column;
    gap: var(--space-6xs);
    align-items: flex-start;
  }

  .infoData span {
    display: none;
  }

  .infoData .vin {
    display: none;
  }

  .addAutoButton {
    height: 184px;
  }
}
