.form {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  width: 100%;
  padding: var(--space-xs) var(--space-s);
}

.title {
  padding-top: 12px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  width: 100%;
}

.formSection:first-child {
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-5xs);
  width: 100%;
}

.inputBlock > p {
  color: var(--gray-400);
}

.footer {
  gap: var(--space-3xs);
}

@media screen and (width <= 575px) {
  .title {
    padding-top: 3px;
  }

  .errorBlock {
    padding: var(--space-xs);
  }

  .form {
    gap: var(--space-s);
    padding: var(--space-xs);
  }

  .footer {
    align-items: center;
    padding: var(--space-3xs) var(--space-xs);
    flex-direction: column-reverse;
    text-align: center;
  }

  .footer > button {
    width: 100%;
  }

  .textBlock {
    align-items: center;
    margin-right: unset;
    flex-wrap: wrap;
  }
}
