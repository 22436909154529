.success {
  display: flex;
  flex-direction: column;
  height: 270px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.success > button {
  margin-top: auto;
  margin-left: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.photos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.radiobtns {
  display: grid;
  gap: 1rem;
}

.radiobtns h6:not(:first-child) {
  margin-top: 1rem;
}

.manually {
  color: var(--red-300);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.manually svg {
  fill: var(--red-300);
}

.carPicker {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.error {
  padding: 20px 40px 0;
}

@media screen and (width <= 767px) {
  .form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (width <= 575px) {
  .photos {
    grid-template-columns: repeat(2, 1fr);
  }

  .success {
    height: 100vh;
    position: relative;
    text-align: center;
  }

  .header {
    margin-top: auto;
  }

  .success h4 {
    text-align: center;
    margin-bottom: 12px;
  }

  .success > button {
    margin-top: auto;
    width: 100%;
  }

  .header > button {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .error {
    padding: 12px 1.5rem 0;
  }
}
