.item {
  animation: oscillating 2s ease infinite;
  background-color: var(--gray-300);
}

@keyframes oscillating {
  0% { opacity: 100%; }
  50% { opacity: 50%; }
  100% { opacity: 100%; }
}
