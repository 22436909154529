@keyframes overlay-show {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.overlay {
  display: none;
}

@media screen and (width <= 1023px) {
  .menuHeader {
    display: grid;
    grid-template-columns: 1fr 54px;
    gap: 24px;
    align-items: flex-start;
    margin-bottom: var(--space-s);
  }

  .phone {
    color: var(--gray-400);
    width: fit-content;
    transition: 0.3s color;
    margin-top: 16px;
  }

  .phone:hover {
    color: var(--black-text);
  }

  .menuMain {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    width: 100%;
  }

  .menuMain::-webkit-scrollbar {
    display: none;
  }

  .groupButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
    margin-bottom: var(--space-xs);
    gap: var(--space-5xs);
  }

  .groupButtons > button,
  .groupButtons > a {
    width: 100%;
  }

  .servicesButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--space-4xs);
    margin-bottom: 32px;
  }

  .servicesButton {
    width: 100%;
    padding: 0 var(--space-2xs);
    justify-content: flex-start;
    border-radius: var(--corner-radius-m);
    height: 72px;
  }

  .navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--space-3xs);
  }

  .navButton {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--space-3xs);
    border-bottom: 1px solid var(--transparent-gray-200);
    display: flex;
  }

  .navigation .navButton:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .buttonIcon {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 1px solid var(--transparent-gray-200);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  .buttonIcon > svg {
    width: 24px;
    height: 24px;
    fill: var(--black-text);
  }

  .overlay {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: right;
    background: var(--transparent-gray-400);
    overflow: hidden;
    z-index: 99;
    inset: 0;
  }

  .wrap {
    max-width: 626px;
    width: 81.5%;
    background: var(--white);
    border-radius: var(--corner-radius-l) 0 0 var(--corner-radius-l);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 24px 40px 40px;
    animation: overlay-show 300ms;
  }

  .close {
    width: 54px;
    height: 54px;
    border: 1px solid var(--transparent-gray-200);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close svg {
    fill: var(--gray-400);
    width: 24px;
    height: 24px;
  }
}

@media screen and (width <= 575px) {
  .menuHeader {
    margin-bottom: var(--space-3xs);
  }

  .phone {
    margin-top: var(--space-4xs);
  }

  .groupButtons {
    grid-template-columns: 1fr;
  }

  .servicesButtons {
    gap: var(--space-5xs);
    margin-bottom: 24px;
  }

  .servicesButton {
    height: 64px;
  }

  .wrap {
    max-width: 100%;
    width: 100%;
    margin-left: 24px;
    padding: 12px 12px 24px 24px;
  }
}
