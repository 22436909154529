.container {
  width: 100%;
  padding: 3rem;
  max-width: 1440px;
  margin: 0 auto;
}

@media (width <= 1199px) {
  .container {
    padding: 3rem 2.5rem;
  }
}

@media (width <= 1023px) {
  .container {
    padding: 3rem 2rem;
  }
}

@media (width <= 767px) {
  .container {
    padding: 2.25rem 1rem;
  }
}
