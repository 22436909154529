.title {
  padding-top: 12px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  width: 100%;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-5xs);
  width: 100%;
}

.help {
  color: var(--gray-400);
}

@media screen and (width <= 575px) {
  .title {
    padding-top: 3px;
  }

  .footer > button {
    width: 100%;
  }
}
