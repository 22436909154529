.review {
  border-radius: 1rem;
  background: var(--gray-100);
  padding: 2.5rem;
  width: 100%;
}

.author {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.author p:last-child {
  color: var(--gray-400);
  margin-top: 4px;
}

.avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.avatar img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.text {
  overflow: hidden;
  transition: 0.2s height;
  margin-bottom: 1rem;
}

.text h6 {
  margin-bottom: 1rem;
}

.textShowed {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.content button {
  width: fit-content;
  color: var(--gray-400);
}

.reviewText {
  white-space: pre-wrap;
}

@media (width <= 767px) {
  .review {
    padding: 24px;
  }

  .author {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .avatar {
    width: 64px;
    height: 64px;
  }
}
