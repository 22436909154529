.rdp {
  background-color: var(--white);
  box-shadow: var(--shadow-100);
  border-radius: var(--corner-radius-m);
  font-size: 12px;
  width: fit-content;
  position: relative;
  z-index: 0;
}

.rdp-caption {
  border-bottom: 1px solid var(--transparent-gray-200);
  position: relative;
}

.rdp-nav_button svg {
  display: none;
}

.rdp-nav_button {
  position: absolute;
  top: 16px;
  height: 1rem;
  width: 1rem;
  display: block;
  z-index: 2;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-nav_button_previous {
  background-image: url('assets/img/icons/arrow-for-calendar.svg');
  left: 20px;
}

.rdp-nav_button_next {
  background-image: url('assets/img/icons/arrow-next-for-calendar.svg');
  right: 20px;
}

.rdp-caption_label {
  font-size: 14px;
  line-height: 3rem;
  font-weight: var(--font-medium);
  text-align: center;
}

.rdp-caption_label::first-letter {
  text-transform: uppercase;
}

.rdp-table {
  border-collapse: collapse;
  padding: 12px 14px 14px;
  display: block;
}

.rdp-head_cell {
  color: var(--gray-400);
  text-transform: uppercase;
  font-weight: var(--font-regular);
  width: 2rem;
  line-height: 1rem;
  padding: 2px 2px 12px;
}

.rdp-cell {
  padding: 2px;
}

.rdp-cell .rdp-button {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  line-height: 2rem;
  text-align: center;
  font-size: 12px;
}

.rdp-cell .rdp-button:hover {
  background-color: var(--gray-100);
}

.rdp-day_today {
  color: var(--red-300);
}

.rdp-cell .rdp-button.rdp-day_selected {
  background-color: var(--red-300);
  color: var(--white);
}

.react-datepicker__close-icon,
.react-datepicker__input-container::after {
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 19px;
  z-index: 2;
  display: block;
}

.react-datepicker__close-icon {
  /* TODO */

  /* mask-image: url(/img/icons/close-for-calendar.svg); */
  background-color: var(--gray-400);
  right: 56px;
}

.react-datepicker__input-container::after {
  content: '';

  /* TODO */

  /* mask-image: url(/img/icons/calendar-icon.svg); */
  background-color: var(--black);
  right: 24px;
}

.react-datepicker__input-container input {
  border-radius: 30px;
  border: 1px solid var(--gray-200);
  padding: 0 1.5rem;
  width: 100%;
  transition: 0.2s border-color;
  background-color: var(--white);
  cursor: text;
  resize: none;
  height: 54px;
}

.react-datepicker__input-container:focus::after {
  background-color: var(--red-300);
}

.react-datepicker__input-container input:hover {
  border-color: var(--gray-300);
}

.react-datepicker__input-container input:focus {
  border-color: var(--red-300);
}

.react-datepicker-wrapper.error input {
  border-color: var(--red-300);
}

.react-datepicker__input-container input::placeholder {
  color: var(--gray-400);
}

.react-datepicker__input-container input:disabled {
  background-color: var(--gray-100);
  color: var(--gray-400);
}

.react-datepicker__input-container input:disabled::placeholder {
  color: var(--gray-300);
}

.react-datepicker__input-container input:disabled + .react-datepicker__close-icon {
  background-color: var(--gray-300);
}
