.searchWrapper {
  width: 100%;
  position: relative;
}

.search {
  width: 100%;
}

@media screen and (width <= 575px) {
  .searchWrapper {
    display: none;
    position: initial;
  }

  .open.searchWrapper {
    display: flex;
  }
}
