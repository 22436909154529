.tags {
  display: flex;
  width: fit-content;
  gap: var(--space-6xs);
  flex-wrap: wrap;
}

.tag {
  padding: 0 11px;
  border: 1px solid var(--transparent-gray-200);
  border-radius: 40px;
  display: flex;
  align-items: center;
  height: 28px;
  width: fit-content;
  flex-shrink: 0;
  font-weight: var(--font-regular);
}
