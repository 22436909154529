.btn {
  width: 54px;
  height: 54px;

  /* border: 1px solid transparent; */
  border: 1px solid var(--gray-200);
  border-radius: 50%;

  /* background-color: var(--gray-200); */
  display: flex;
  align-items: center;
  color: var(--gray-400);
  justify-content: center;
  position: relative;
  flex-shrink: 0;
}

.btn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.btn:not(.large):hover .initials {
  color: var(--white);
}

.btn:focus {
  border-color: var(--red-300);
}

.btn.large {
  width: 120px;
  height: 120px;
}

.control {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: var(--black);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.control svg {
  fill: var(--white);
  width: 1rem;
  height: 1rem;
}

.large .icon {
  fill: var(--black);
  width: 48px;
  height: 48px;
}

.control input {
  display: none;
}
