@import '../breakpoints';

/* stylelint-disable */
ymaps {
  box-shadow: none !important;
}

ymaps[class$='controls-pane'] {
  height: 100%;
  width: 0 !important;
  left: unset !important;
  right: 0;
}

ymaps[class$='copyrights-pane'] {
  z-index: 6000 !important;
}

ymaps[class$='balloon__layout'] {
  background-color: var(--white);
  border-radius: 20px;
  padding: 1rem;
  z-index: 2;
  box-sizing: border-box !important;
}

ymaps[class$='balloon__close'] {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: 0;
  float: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

ymaps[class$='balloon__close-button'] {
  width: 1rem;
  height: 1rem;
  opacity: 1;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1257_23953)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.93895 7.99961L0.519531 14.419L1.58019 15.4797L7.99961 9.06027L14.419 15.4797L15.4797 14.419L9.06027 7.99961L15.4797 1.58019L14.419 0.519531L7.99961 6.93895L1.58019 0.519531L0.519531 1.58019L6.93895 7.99961Z' fill='%2371716F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1257_23953'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

ymaps[class$='balloon__tail'] {
  background: none;
}

ymaps[class$='balloon__tail']::after {
  border-radius: 4px;
}

ymaps[class$='balloon__content'] {
  margin-right: 0 !important;
  padding: 0;
  font-family: 'Gotham Pro', sans-serif;

  & > ymaps {
    height: unset !important;
  }
}

.balloon {
  width: 320px;
  min-height: 132px;
  display: flex;
}

@media screen and (width <= $bp-576) {
  .balloon {
    min-height: 76px;
    width: unset;
    height: 100%;
    padding: 12px 16px;
  }
}

@media screen and (width <= 507px) {
  .balloon {
    width: initial;
    padding: 1rem 1.5rem;
  }
}
