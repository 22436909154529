.section_line {
  border-top: 1px solid var(--transparent-gray-200);
  height: 1px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 40px;
}

.info {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.about_block p {
  color: var(--gray-400);
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
}

.about_info {
  display: flex;
  align-items: center;
  gap: 23px;
}

.about_info > h5 {
  text-overflow: ellipsis;
  overflow: hidden;
}

.edit {
  width: 24px;
  height: 24px;
}

.edit svg {
  fill: var(--red-300);
}

.about_btn {
  padding: 16px 32px;
  border-radius: 40px;
  border: 1px solid var(--transparent-gray-200);
}

@media (width <= 575px) {
  .section_line {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .info {
    margin-top: 32px;
    gap: 24px;
  }

  .about_info {
    justify-content: space-between;
  }

  .about_btn {
    width: 100%;
  }

  .about_btn h6 {
    text-align: center;
  }
}
