.title {
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.loadMore {
  margin: var(--space-s) auto 0;
}

@media screen and (width <= 767px) {
  .loadMore {
    width: 100%;
  }
}
