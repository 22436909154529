@import '../../styles/breakpoints';

.headerButton {
  width: max-content;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

.promo-1 {
  grid-template-columns: 1fr;
}

.promo-4 {
  grid-template-columns: repeat(2, 1fr);
}

.promo:not(.promo-4, .promo-1) > a:first-child,
.promo-5 > a:nth-child(4) {
  grid-column: 1 / 3;
}

.promo:not(.promo-3, .promo-4, .promo-1) > a:last-child {
  grid-column: 3 / 5;
}

.mapSection {
  padding: 0;
  overflow: unset;
}

.mapHeader {
  display: none;
}

@media screen and (width <= 1199px) {
  .grid {
    gap: 1rem;
  }
}

@media screen and (width <= 1023px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .promo-1 {
    grid-template-columns: 1fr;
  }

  .promo:not(.promo-4, .promo-1, .promo-5, .promo-3) > a:first-child,
  .promo-5 > a:nth-child(4),
  .promo:not(.promo-3, .promo-4, .promo-1) > a:last-child {
    grid-column-start: unset;
    grid-column-end: unset;
  }
}

@media screen and (width <= $bp-920) {
  .heroSection {
    height: 80vh;
    height: 80svh;
    position: relative;

    :global(.hero-content) {
      gap: 0;
    }

    :global(.hero-footer:before) {
      display: none;
    }

    :global(.hero-img-wrapper) {
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, #cb2929 200px, #00428d 200px);
      display: flex;

      img {
        align-self: auto;
        //noinspection CssInvalidPropertyValue
        contain: inline-size;
        width: unset;
        aspect-ratio: 1358 / 1166;
        background: #cb2929;
      }
    }
  }

  .headerButton {
    background-color: var(--white);
    color: var(--black-text);
    border: none;
    position: absolute;
    bottom: 36px;
    right: 36px;

    &:not(:disabled):hover {
      background-color: var(--bg-gray);
    }
  }
}

@media (width <= 767px) {
  .withHeader {
    padding-bottom: 94px;
  }

  .mapHeader {
    display: block;
    padding: 36px 36px 0 36px;

    header {
      border: none;
      margin: 0;
    }
  }
}

@media screen and (width <= $bp-576) {
  .grid {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .services_grid > a:nth-child(n + 6) {
    display: none;
  }

  .promo:not(.promo-4, .promo-1) > a:first-child,
  .promo-5 > a:nth-child(4) {
    grid-column: 1 / 2;
  }

  .heroSection {
    height: 45vh;
    height: 45svh;
  }

  .headerButton {
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    bottom: 16px;
  }

  .title {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .subtitle {
    font-size: 0.875rem;
    line-height: 1.43;
  }

  .mapSection {
    flex-grow: 1;
    display: flex;

    :global(.map) {
      min-height: 320px;
      height: 100%;
    }
  }

  .mapHeader {
    display: block;
    padding: 16px 16px 0 16px;
  }

  .firstBlocks {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 94px);
  }
}

@media screen and (width <= $bp-420) {
  .heroSection {
    height: unset;

    :global(.hero-img-wrapper) {
      display: none;
    }

    :global(.hero-content) {
      row-gap: 8px;
    }

    :global(.hero-footer) {
      background-image: url('../../assets/img/home/hero/bg.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding-top: 16px;
    }
  }

  .headerButton {
    position: relative;
    bottom: unset;
  }
}
