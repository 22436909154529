@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  border-radius: 40px;
  background: var(--black-text);
  color: var(--white);
  font-weight: 500;
  padding: 15px 31px;
  border: 1px solid var(--black-text);
  fill: var(--white);
}

.btn > svg {
  width: 24px;
  height: 24px;
  fill: inherit;
}

.loadingWrap {
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  background: none;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--white);
}

.loadingWrap > svg {
  fill: inherit;
  animation: spin 1.3s linear infinite;
}

.btn:hover {
  color: var(--red-300);
  fill: var(--red-300);
}

.btn:disabled,
.btn:hover:disabled {
  background: var(--transparent-gray-200);
  border-color: var(--transparent-gray-100);
  color: var(--gray-300);
  fill: var(--gray-300);
}

.btn:focus {
  border-color: var(--red-300);
}

.gray {
  background: var(--gray-400);
  border-color: var(--gray-400);
}

.gray:hover {
  background: var(--gray-400);
  border-color: var(--gray-400);
  color: var(--gray-200);
  fill: var(--gray-200);
}

.gray:focus {
  border-color: var(--red-300);
}

.orange,
.orange:focus {
  background: var(--red-300);
  border-color: var(--red-300);
}

.orange:hover {
  background: var(--red-200);
  border-color: var(--red-200);
  color: var(--white);
  fill: var(--white);
}

.white,
.white:focus {
  background: var(--white);
  border-color: var(--white);
  color: var(--black-text);
  fill: var(--black-text);
}

.white:hover {
  background: var(--white);
  border-color: var(--white);
  color: var(--red-300);
  fill: var(--red-300);
}

.btn.white:disabled,
.btn.white:hover:disabled {
  background: var(--transparent-gray-100);
  border-color: var(--no-color);
}

.white .loadingWrap {
  fill: var(--black);
}

.secondary {
  border-color: var(--transparent-gray-200);
  background: none;
  color: var(--black-text);
  fill: var(--black-text);
}

.secondary:hover {
  border-color: var(--transparent-gray-100);
  background: var(--transparent-gray-200);
  color: var(--black-text);
  fill: var(--black-text);
}

.secondary:disabled,
.secondary:hover:disabled {
  border-color: var(--transparent-gray-200);
  background: none;
  color: var(--transparent-gray-200);
  fill: var(--transparent-gray-200);
}

.secondary:focus {
  border-color: var(--red-300);
  background: none;
  color: var(--black-text);
  fill: var(--black-text);
}

.secondary .loadingWrap {
  fill: var(--black-text);
}

.secondary.gray {
  color: var(--gray-400);
  fill: var(--gray-400);
}

.secondary.gray:hover {
  color: var(--gray-400);
  fill: var(--gray-400);
}

.secondary.gray:disabled,
.secondary.gray:disabled:hover {
  border-color: var(--transparent-gray-200);
  background: none;
  color: var(--transparent-gray-200);
  fill: var(--transparent-gray-200);
}

.secondary.gray:focus {
  color: var(--gray-400);
  fill: var(--gray-400);
}

.secondary.gray .loadingWrap {
  fill: var(--gray-400);
}

.secondary.orange {
  color: var(--red-300);
  fill: var(--red-300);
}

.secondary.orange:disabled,
.secondary.orange:disabled:hover {
  border-color: var(--transparent-gray-200);
  background: none;
  color: var(--transparent-gray-200);
  fill: var(--transparent-gray-200);
}

.secondary.orange:focus {
  border-color: var(--red-300);
  background: none;
  color: var(--red-300);
  fill: var(--red-300);
}

.secondary.orange .loadingWrap {
  fill: var(--red-300);
}

.secondary.white {
  border-color: var(--transparent-gray-100);
  color: var(--white);
  fill: var(--white);
}

.secondary.white:hover {
  border-color: var(--no-color);
  background: var(--transparent-gray-100);
}

.secondary.white:disabled,
.secondary.white:disabled:hover {
  border-color: var(--transparent-gray-100);
  background: none;
  color: var(--transparent-gray-100);
  fill: var(--transparent-gray-100);
}

.secondary.white:focus {
  border-color: var(--red-300);
  background: none;
  color: var(--white);
  fill: var(--white);
}

.secondary.white .loadingWrap {
  fill: var(--white);
}

.btn.loadingText,
.btn.loadingText:hover,
.btn.loadingText:disabled,
.btn.loadingText:hover:disabled,
.btn.loadingText:focus,
.secondary.loadingText,
.secondary.loadingText:hover,
.secondary.loadingText:disabled,
.secondary.loadingText:hover:disabled,
.secondary:focus.loadingText {
  color: var(--no-color);
  fill: var(--no-color);
}

.btn:disabled {
  cursor: not-allowed;
}

/* button-link */

.btnLink {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  color: var(--black-text);
  fill: var(--black-text);
}

.btnLink svg {
  fill: inherit;
  width: 16px;
  height: 16px;
}

.loadingLink svg {
  animation: spin 1.3s linear infinite;
}

.btnLink:hover {
  color: var(--gray-500);
  fill: var(--gray-500);
}

.btnLink:disabled,
.btnLink:disabled:hover,
.orangeLink:disabled,
.orangeLink:disabled:hover,
.grayLink:disabled,
.grayLink:disabled:hover,
.whiteLink:disabled,
.whiteLink:disabled:hover {
  cursor: not-allowed;
  color: var(--gray-300);
  fill: var(--gray-300);
}

.btnLink:focus {
  outline: 1px solid var(--red-300);
  border-radius: var(--corner-radius-xs);
}

.orangeLink {
  color: var(--red-300);
  fill: var(--red-300);
}

.orangeLink:hover {
  color: var(--red-200);
  fill: var(--red-200);
}

.grayLink {
  color: var(--gray-400);
  fill: var(--gray-400);
}

.grayLink:hover {
  color: var(--black-text);
  fill: var(--black-text);
}

.whiteLink {
  color: var(--white);
  fill: var(--white);
}

.whiteLink:hover {
  color: var(--gray-100);
  fill: var(--gray-100);
}

/* button-icon */

.btnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  fill: var(--white);
  background: var(--black-text);
}

.btnLarge {
  width: 54px;
  height: 54px;
}

.btnMedium {
  width: 32px;
  height: 32px;
}

.btnIcon svg {
  fill: inherit;
  width: 24px;
  height: 24px;
}

.loadingIcon svg {
  animation: spin 1.3s linear infinite;
}

.btnIcon:hover {
  fill: var(--red-300);
  background: var(--black-text);
}

.btnIcon.loadingIcon:hover {
  fill: var(--white);
}

.btnIcon:disabled,
.btnIcon:disabled:hover {
  cursor: not-allowed;
}

.btnIcon:disabled,
.btnIcon:disabled:hover,
.orangeIcon:disabled,
.orangeIcon:disabled:hover,
.grayIcon:disabled,
.grayIcon:disabled:hover {
  fill: var(--gray-300);
  background: var(--transparent-gray-100);
}

.btnIcon:focus,
.btnIcon.secondaryIcon:focus,
.btnIcon.tertiaryIcon:focus {
  border: 1px solid var(--red-300);
}

.orangeIcon {
  background: var(--red-300);
}

.orangeIcon:hover {
  fill: var(--white);
  background: var(--red-200);
}

.orangeIcon.loadingIcon:hover {
  fill: var(--white);
}

.grayIcon {
  background: var(--gray-400);
}

.grayIcon:hover {
  background: var(--gray-500);
  fill: var(--white);
}

.grayIcon.loadingIcon:hover {
  fill: var(--white);
}

.whiteIcon {
  background: var(--white);
  fill: var(--black-text);
}

.whiteIcon:hover {
  background: var(--white);
  fill: var(--red-300);
}

.whiteIcon.loadingIcon:hover {
  fill: var(--black-text);
}

.btnIcon.whiteIcon:disabled,
.btnIcon.whiteIcon:disabled:hover {
  background: var(--transparent-gray-200);
  fill: var(--gray-300);
}

.btnIcon.secondaryIcon {
  background: none;
  fill: var(--black-text);
  border: 1px solid var(--transparent-gray-200);
}

.btnIcon.secondaryIcon:hover {
  fill: var(--black-text);
  background: var(--transparent-gray-200);
  border: 1px solid var(--no-color);
}

.btnIcon.secondaryIcon.loadingIcon:hover {
  fill: var(--black-text);
}

.btnIcon.secondaryIcon:disabled,
.btnIcon.secondaryIcon:disabled:hover,
.secondaryIcon.orangeIcon:disabled,
.secondaryIcon.orangeIcon:disabled:hover,
.secondaryIcon.grayIcon:disabled,
.secondaryIcon.grayIcon:disabled:hover {
  fill: var(--transparent-gray-200);
  background: none;
  border: 1px solid var(--transparent-gray-200);
}

.secondaryIcon.orangeIcon {
  fill: var(--red-300);
}

.secondaryIcon.orangeIcon:hover {
  fill: var(--red-300);
}

.secondaryIcon.orangeIcon.loadingIcon:hover {
  fill: var(--red-300);
}

.secondaryIcon.grayIcon {
  fill: var(--gray-400);
}

.secondaryIcon.grayIcon:hover {
  fill: var(--gray-400);
}

.secondaryIcon.grayIcon.loadingIcon:hover {
  fill: var(--gray-400);
}

.secondaryIcon.whiteIcon {
  fill: var(--white);
  border: 1px solid var(--transparent-gray-100);
}

.secondaryIcon.whiteIcon:hover {
  fill: var(--white);
  background: var(--transparent-gray-100);
}

.secondaryIcon.whiteIcon.loadingIcon:hover {
  fill: var(--white);
}

.secondaryIcon.whiteIcon:disabled,
.secondaryIcon.whiteIcon:disabled:hover {
  fill: var(--transparent-gray-100);
  background: none;
  border: 1px solid var(--transparent-gray-100);
}

.btnIcon.tertiaryIcon {
  fill: var(--black-text);
  border: none;
  background: none;
}

.btnIcon.tertiaryIcon:hover {
  fill: var(--gray-500);
  background: none;
  border: none;
}

.btnIcon.tertiaryIcon.loadingIcon:hover {
  fill: var(--black-text);
}

.btnIcon.tertiaryIcon:disabled,
.btnIcon.tertiaryIcon:disabled:hover,
.tertiaryIcon.orangeIcon:disabled,
.tertiaryIcon.orangeIcon:disabled:hover,
.tertiaryIcon.grayIcon:disabled,
.tertiaryIcon.grayIcon:disabled:hover {
  fill: var(--transparent-gray-200);
  background: none;
  border: none;
}

.tertiaryIcon.orangeIcon {
  fill: var(--red-300);
}

.tertiaryIcon.orangeIcon:hover {
  fill: var(--red-200);
  background: none;
  border: none;
}

.tertiaryIcon.orangeIcon.loadingIcon:hover {
  fill: var(--red-300);
}

.tertiaryIcon.grayIcon {
  fill: var(--gray-400);
}

.tertiaryIcon.grayIcon:hover {
  fill: var(--gray-500);
}

.tertiaryIcon.grayIcon.loadingIcon:hover {
  fill: var(--gray-400);
}

.tertiaryIcon.whiteIcon {
  fill: var(--white);
}

.tertiaryIcon.whiteIcon:hover {
  fill: var(--gray-100);
}

.tertiaryIcon.whiteIcon.loadingIcon:hover {
  fill: var(--white);
}

.tertiaryIcon.whiteIcon:disabled,
.tertiaryIcon.whiteIcon:disabled:hover {
  fill: var(--transparent-gray-100);
}
