@import '../../../styles/breakpoints';

.wrapper {
  position: relative;
  padding: 0;
  height: 100%;
  width: 100%;
}

.fakeMap {
  position: absolute;
  left: 0;
  top: 0;
}

.map {
  position: relative;
  height: 780px;
  border-radius: var(--corner-radius-l);
  overflow: hidden;
  background: color-mix(in srgb, var(--gray-300), var(--black) 20%);
}

.cluster {
  background-color: var(--red-300);
  width: 40px;
  height: 40px;
  color: var(--white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (width <= $bp-1200) {
  .map {
    height: 736px;
  }
}

@media screen and (width <= $bp-1024) {
  .map {
    height: 720px;
  }
}

@media screen and (width <= $bp-768) {
  .map {
    height: 320px;
    border-radius: 0 0 var(--corner-radius-l) var(--corner-radius-l);
  }
}

@media screen and (width <= $bp-576) {
  .map {
    :global(.balloon-button) {
      display: none;
    }
  }
}
