.title {
  padding-bottom: var(--space-xs);
  border-bottom: 1px solid var(--transparent-gray-200);
  margin-bottom: var(--space-s);
}

.tabsWrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: var(--space-s);
}

.tabs {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--space-5xs);
}

.bannersGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-2xs);
  width: 100%;
}

@media screen and (width <= 1199px) {
  .bannersGroup {
    gap: var(--space-3xs);
  }
}

@media screen and (width <= 1023px) {
  .bannersGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (width <= 767px) {
  .bannersGroup {
    grid-template-columns: repeat(1, 1fr);
    gap: var(--space-4xs);
  }
}
