@import '../../../styles/breakpoints';

.section {
  width: 100%;
  background-color: var(--white);
  padding: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
}

.content {
  max-width: 620px;
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  padding: 40px 0 40px 40px;
  margin-right: -64px;

  p {
    padding-right: 40px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
}

.links {
  display: flex;
  column-gap: 24px;
}

.qr {
  width: 120px;
  height: 120px;

  svg {
    width: inherit;
    height: inherit;
  }
}

.stores {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.storeLink {
  background-color: var(--dark-gray);
  padding: 0 24px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;

  img {
    height: 36px;
    width: auto;
  }
}

.img {
  img {
    aspect-ratio: 1008 / 563;
    object-position: left bottom;
    object-fit: cover;
    height: 100%;
  }
}

@media screen and (width <= $bp-1200) {
  .content {
    padding: 36px 0 36px 36px;
  }
}

@media screen and (width <= $bp-576) {
  .section {
    background-image: url('../../../assets/img/home/app/mobile-bg.svg');
    background-position: top right;
    background-repeat: no-repeat;
    display: block;
  }

  .content {
    max-width: unset;
    row-gap: 18px;
    padding: 16px;
    width: 100%;
  }

  .wrapper {
    flex-direction: column-reverse;
    row-gap: 36px;
  }

  .title {
    padding-right: 52px;
  }

  .links {
    flex-direction: column;
    row-gap: 16px;
  }

  .qr,
  .img {
    display: none;
  }

  .stores {
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
  }

  .storeLink {
    height: unset;
    padding: 8px 12px;
    flex: 1;
    justify-content: center;
  }
}
