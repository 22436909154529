.gsap span.active {
  opacity: 1;
}

.gsap-navigation li.active {
  border-color: var(--orange-300);
}

.gsap-navigation li.active::after {
  background-color: var(--orange-300);
}
