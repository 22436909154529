.wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: var(--space-4xs);
}

.colorButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: fit-content;
  gap: var(--space-6xs);
}

.label {
  display: flex;
  width: fit-content;
  position: relative;
}

.label input[type='radio'] {
  display: none;
}

.buttonOuter {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  flex-shrink: 0;
  transition: 0.2s border;
  border: 2px solid var(--no-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonOuter::after {
  content: '';
  width: 36px;
  height: 36px;
  border-radius: 70px;
  background: var(--colorVariable);
}

.lightColor::after {
  border: 2px solid var(--gray-300);
  box-sizing: border-box;
  transition: 0.3s border;
}

.label input:checked + .buttonOuter {
  border-color: var(--colorVariable);
}

.label input:checked + .lightColor {
  border: 2px solid var(--gray-300);
}

.label input:disabled + .buttonOuter {
  opacity: 0.2;
}
