* {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.2s color;
  cursor: pointer;
}

button {
  background: transparent;
  padding: 0;
  border: none;
  font-family: inherit;
  cursor: pointer;
  font-size: 1rem;
  line-height: inherit;
  flex-shrink: 0;
  outline: none;
  font-weight: var(--font-regular);
  color: inherit;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: var(--font-medium);
}

iframe {
  width: 100%;
}

figure,
blockquote {
  margin: 0;
}

label {
  cursor: pointer;
}

input,
textarea {
  outline: none;
  font-family: inherit;
  font-size: 1rem;
  border: none;
  padding: 0;
  background-color: transparent;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
