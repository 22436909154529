.wrapper {
  position: relative;
  transition: 0.3s padding;
}

.title {
  color: var(--white);
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 3rem 0 0 3rem;
  opacity: 0;
  transition: 0.1s opacity;
}

.active > .title {
  opacity: 1;
}

.videoWrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.videoWrap video {
  display: block;
  width: 100%;
  height: 101vh;
  position: sticky;
  left: 0;
  top: 0;
  object-fit: cover;
  scale: 0.95;
  filter: brightness(1);
  border-radius: 1rem;
}

.text {
  position: relative;
  z-index: 2;
  max-width: 900px;
  width: 100%;

  /* todo: hid franchise video text */

  /* padding: 100vh 36px 62px; */
  padding: 100vh 36px 120px;
  margin-left: auto;
  color: var(--gray-200);
}

.text span {
  opacity: 0.3;
  transition: 0.3s opacity ease;
}

.text span.active {
  opacity: 1;
}

.spacer {
  height: 100vh;
}

@media screen and (width <= 1199px) {
  .title {
    padding: 2.5rem 0 0 2.5rem;
  }

  .text {
    max-width: 544px;

    /* todo: hid franchise video text */

    /* padding-bottom: 120px; */
  }
}
