.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-2xs);
  width: 100%;
  padding: 20px 20px 20px var(--space-s);
  border-bottom: 1px solid var(--transparent-gray-200);
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--space-xs) var(--space-s) var(--space-s);
  gap: var(--space-xs);
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: var(--space-5xs);
  width: 100%;
}

.inputBlock > p {
  color: var(--gray-400);
}

.buttonSection {
  width: 100%;
  display: flex;
  padding: var(--space-2xs) var(--space-s);
  justify-content: flex-end;
  border-top: 1px solid var(--transparent-gray-200);
}

@media screen and (width <= 575px) {
  .header {
    padding: 12px 12px 12px var(--space-xs);
  }

  .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    gap: var(--space-2xs);
  }

  .fieldsSection {
    padding: var(--space-xs) var(--space-xs);
    gap: var(--space-xs);
  }

  .buttonSection {
    padding: var(--space-3xs) var(--space-xs);
  }

  .buttonSection > button {
    width: 100%;
  }
}
