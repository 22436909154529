.cardWrap {
  display: flex;
  max-width: 312px;
  width: 100%;
  flex-direction: column;
  border-radius: var(--corner-radius-m);
  overflow: hidden;
  background: var(--gray-100);
  transition: 0.2s background;
}

.cardWrap:hover {
  background: var(--gray-200);
}

.imageWrap {
  width: 100%;
  position: relative;
  border-radius: var(--corner-radius-m);
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
}

.imageSpace {
  width: 100%;
  padding-bottom: 77%;
}

.imageWrap img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.contentBlock {
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  gap: var(--space-3xs);
  padding: var(--space-3xs);
}

.cardTitle {
  display: -webkit-box;
  width: 100%;
  height: 88px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (width <= 1199px) {
  .cardWrap {
    max-width: unset;
  }

  .imageSpace {
    padding-bottom: 76%;
  }
}

@media screen and (width <= 1023px) {
  .imageSpace {
    padding-bottom: 77%;
  }

  .cardTitle {
    height: 66px;
    -webkit-line-clamp: 3;
  }
}

@media screen and (width <= 767px) {
  .imageSpace {
    padding-bottom: 76.5%;
  }

  .cardTitle {
    height: 88px;
    -webkit-line-clamp: 4;
  }
}
