.filterButton {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--transparent-gray-200);
  padding-bottom: 1rem;
  column-gap: 12px;
}

.arrow {
  display: flex;
  align-items: center;
  transition: 0.2s transfrom;
  padding: 8px;
  cursor: pointer;

  svg {
    fill: var(--black);
  }
}

.filterButtonOpen .arrow {
  transform: rotate(180deg);
}

.list {
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

@media screen and (width <= 1199px) {
  .wrapper {
    max-width: 374px;
    top: 36px;
    bottom: 36px;
    left: 36px;
  }
}

@media screen and (width <= 1023px) {
  .wrapper {
    max-width: 320px;
  }
}
