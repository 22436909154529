.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: var(--font-medium);
  text-align: left;
}

.h1 {
  font-size: 3.875rem;
  line-height: 1.1;
}

.h2 {
  font-size: 3.25rem;
  line-height: 1.12;
}

.h3 {
  font-size: 2rem;
  line-height: 1.19;
}

.h4 {
  font-size: 1.5rem;
  line-height: 1.25;
}

.h5 {
  font-size: 1.25rem;
  line-height: 1.3;
}

.h6 {
  font-size: 1rem;
  line-height: 1.38;
}

.pm1 {
  font-size: 0.875rem;
  line-height: 1.43;
  font-weight: var(--font-medium);
}

.pm2 {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: var(--font-medium);
}

.p1 {
  font-size: 1.25rem;
  line-height: 1.3;
}

.p2 {
  line-height: 1.38;
}

.p3 {
  font-size: 0.875rem;
  line-height: 1.43;
}

.p4 {
  font-size: 0.75rem;
  line-height: 1.33;
}

@media screen and (width <= 1199px) {
  .h4 {
    font-size: 22px;
    line-height: 1.27;
  }
}

@media screen and (width <= 1023px) {
  .h1 {
    font-size: 3.375rem;
    line-height: 1.11;
  }

  .h2 {
    font-size: 2.875rem;
    line-height: 1.13;
  }

  .h3 {
    font-size: 1.625rem;
    line-height: 1.23;
  }

  .h4 {
    font-size: 1.375rem;
    line-height: 1.27;
  }

  .h5 {
    font-size: 1.125rem;
    line-height: 1.33;
  }

  .p1 {
    font-size: 1.125rem;
    line-height: 1.33;
  }
}

@media screen and (width <= 767px) {
  .h1 {
    font-size: 3rem;
    line-height: 1.12;
  }

  .h2 {
    font-size: 2rem;
    line-height: 1.19;
  }

  .h3 {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .h4 {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.a {
  color: var(--orange-300);
}

.h2-52,
.h3-32,
.h5-20,
.h6-16 {
  font-weight: 500;
}

.h2-52 {
  font-size: 3.25rem;
  line-height: 1.12;
}

.h3-32 {
  font-size: 2rem;
  line-height: 1.19;
}

.h4-26 {
  font-size: 26px;
  line-height: 1.23;
}

.h5-20 {
  font-size: 1.25rem;
  line-height: 1.3;
}

.h6-16 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.38;
}

.p2-16 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.38;
}

::placeholder {
  color: var(--black);
  opacity: 1;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  color: inherit;
}

.section-title::before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: currentcolor;
}

@media (width <= 1199px) {
  .h2-52 {
    font-size: 2.875rem;
  }

  .h3-32 {
    font-size: 1.625rem;
    line-height: 1.23;
  }

  .h4-26 {
    font-size: 22px;
    line-height: 1.27;
  }

  .h5-20 {
    font-size: 1.125rem;
    line-height: 1.33;
  }
}

@media (width <= 767px) {
  .h2-52 {
    font-size: 2rem;
  }

  .h3-32 {
    font-size: 1.5rem;
  }

  .title-white-black {
    color: var(--black);
  }

  .title-white-black::before {
    background: var(--black);
  }
}
