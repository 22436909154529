.Partners {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
  margin-bottom: var(--space-5xs);
}

.Carousel {
  min-width: 0;
  position: relative;
}

.CarouselImg {
  border-radius: 32px;
  overflow: hidden;
}

.Main,
.Text {
  display: flex;
  flex-direction: column;
}

.Img,
.Main {
  border-radius: 32px;
  overflow: hidden;
}

.Img > img {
  height: 100%;
}

.Main {
  background-color: var(--black);
  padding: 40px;
  gap: 120px;
  justify-content: center;
  background-image: url('assets/img/spare-parts/bg.png');
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.Text {
  gap: 24px;
  color: var(--white);
}

.Logos {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
}

.Logos > div {
  padding: 8px 12px;
  border-radius: 130px;
  background-color: var(--gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (width <= 1399px) {
  .Logos {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (width <= 1199px) {
  .Main {
    padding: 36px;
    gap: 64px;
  }

  .Logos svg {
    width: 51px;
    height: 34px;
  }
}

@media (width <= 1023px) {
  .Partners {
    grid-template-columns: 1fr;
    grid-column-end: auto;
  }

  .Main {
    order: 1;
  }

  .Img {
    order: 2;
  }

  .Carousel {
    order: 2;
  }

  .Logos {
    grid-template-columns: repeat(8, 1fr);
  }

  .Logos > div {
    padding: 6px 12px;
  }
}

@media (width <= 767px) {
  .Logos {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (width <= 575px) {
  .Img,
  .Main {
    border-radius: 32px;
  }

  .Main {
    gap: 40px;
    padding: 16px;
  }

  .Text {
    gap: 20px;
  }

  .Logos svg {
    width: 36px;
    height: 24px;
  }

  .Logos {
    grid-template-columns: repeat(4, 1fr);
  }
}
