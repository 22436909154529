.pageWrapper {
  display: flex;
  row-gap: 40px;
  flex-direction: column;
}

.titleWrapper {
  border-bottom: 1px solid var(--transparent-gray-200);
  padding-bottom: var(--space-s);
}

.title {
  margin-bottom: var(--space-xs);
}
