.section {
  overflow: hidden;
  position: relative;
}

.sectionTitle {
  position: absolute;
  left: 48px;
  top: 48px;
  z-index: 7;
}

.map {
  position: relative;
  margin-top: 87px;
  margin-bottom: 4rem;
}

.mapBg {
  width: 100%;
  height: auto;
}

.point {
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--orange-300);
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition:
    0.1s width,
    0.1s height;
}

.pointInView {
  width: 48px;
  height: 48px;
}

.point::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background: url('assets/img/icons/plus.svg') no-repeat center;
  transition: 0.3s transform;
  transform: rotate(0);
}

.point.active {
  z-index: 10;
}

.point.active::before {
  transform: rotate(135deg);
}

.tooltip {
  position: absolute;
  z-index: 8;
  transform: translateY(-100%);
}

.tooltipInner {
  padding: 2rem 2.5rem;
  width: 436px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--white);
  border-radius: 1rem;
  box-shadow: 0 4px 24px rgb(84 78 65 / 6%);
  max-height: 320px;
  overflow: hidden;
}

.servicesWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: auto;
}

.service {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tooltipInner button {
  display: none;
}

.costLink {
  font-weight: 500;
  color: var(--orange-300);
}

.costLink::after {
  content: '';
  display: inline-block;
  height: 11px;
  width: 14px;
  margin-left: 9px;
  background: url('assets/img/franchise/icons/right-orange.svg') no-repeat center;
}

.contact {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-400);
  width: fit-content;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.separate {
  border-bottom: 1px solid var(--transparent-gray-200);
}

@media (width <= 1199px) {
  .sectionTitle {
    left: 40px;
  }

  .map {
    margin-top: 0;
  }

  .point {
    width: 42px;
    height: 42px;
  }
}

@media (width <= 1023px) {
  .sectionTitle {
    left: 32px;
  }

  .tooltipInner {
    width: 380px;
  }

  .point {
    width: 38px;
    height: 38px;
  }
}

@media (width <= 767px) {
  .section {
    padding: 2.25rem 1rem 0;
  }

  .tooltip {
    z-index: 11;
    width: calc(100vw - 2rem);
    height: 0;
    transform: translateY(0);
  }

  .tooltipInner {
    width: 100%;
    transform: translateY(-100%);
    padding: 1.25rem;
  }

  .tooltipInner button {
    display: flex;
    width: 11px;
    height: 11px;
    background: url('assets/img/icons/close.svg') no-repeat center;
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    z-index: 2;
  }

  .map {
    margin-bottom: 0;
  }

  .point {
    width: 26px;
    height: 26px;
  }

  .point::before {
    width: 8px;
    height: 8px;
  }
}

@media (width <= 575px) {
  .sectionTitle {
    top: 32px;
    left: 16px;
  }

  .point {
    width: 20px;
    height: 20px;
  }
}

@media (width <= 400px) {
  .point {
    width: 16px;
    height: 16px;
  }
}
